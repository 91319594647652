import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";
import CustomHeader from "../Header";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

export default function ResultSuccess() {
  const { t } = useTranslation("user");
  return (
    <>
      <CustomHeader />
      <Content style={{ padding: "0 200px" }}>
        <Result
          status="success"
          title={t("resultSuccess.title")}
          subTitle={t("resultSuccess.subTitle")}
          extra={[
            <Link to="/user/overview" key="overzicht">
              <Button type="primary">{t("resultSuccess.toOverview")}</Button>
            </Link>,
            <Link to="/user/create-issue" key="newTicket">
              <Button>{t("resultSuccess.newTicket")}</Button>
            </Link>,
          ]}
        />
      </Content>
    </>
  );
}
