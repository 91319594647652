import moment from "moment-timezone";

export const formatDateTime = (timestamp, format = "DD/MM/YYYY HH:mm:ss") => {
  if (timestamp) {
    const date = moment.utc(timestamp);

    return date.tz("Europe/Brussels").format(format);
  }

  return "N/A";
};

export const parseDateTime = (timestamp, format = "DD/MM/YYYY HH:mm:ss") => {
  if (timestamp) {
    const date = moment.utc(timestamp, format);

    return date.tz("Europe/Brussels");
  }

  return "N/A";
};
