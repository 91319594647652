import React from "react";
import moment from "moment";
import qs from "qs";
import { useHistory } from "react-router-dom";
import { Table, Tag, Space, Badge, message, Layout, Card, Tooltip } from "antd";
import { MessageFilled, FlagFilled } from "@ant-design/icons";
import { blue, red, orange, green } from "@ant-design/colors";

import "./Overview.css";
import Filters from "./Filters";

import connect from "../../common/utils/SocketClient";
import { client } from "../../common/utils/ApiClient";
import { formatDateTime } from "../../common/utils/DateHelper";

function dataMapper(data) {
  return {
    key: data.id,
    id: data.id,
    subject: data.subject,
    customer: data.user,
    creationTimestamp: data.createdAt,
    updatedTimestamp: data.lastMessage ? data.lastMessage : data.createdAt,
    status: data.status,
    agent: data.agent ? data.agent : null,
    isUnreadAgent: data.isUnreadAgent,
    hasIvantiResponse: data.hasIvantiResponse,
    priority: data.priority,
  };
}

const priorityColorMapper = (priority) => {
  switch (priority) {
    case 100:
      return green.primary;
    case 200:
      return blue.primary;
    case 300:
      return orange.primary;
    case 400:
      return red.primary;

    default:
      return "";
  }
};

export default function Overview({ user, isActive }) {
  let history = useHistory();
  const [dataSource, setDataSource] = React.useState([]);

  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const [query, setQuery] = React.useState({
    skip: 0,
    limit: 10,
    sort: undefined,
    sortDirection: undefined,
  });

  const [priorities, setPriorities] = React.useState({});

  React.useEffect(() => {
    const queryString = qs.stringify(query);
    client(`tickets/priorities`, {
      token: user.access_token,
    })
      .then((response) => {
        setPriorities(response);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
    client(`tickets/overview?isActive=${isActive}&${queryString}`, {
      token: user.access_token,
    })
      .then((response) => {
        const mappedData = response.result.map((data) => dataMapper(data));
        if (query.skip === 0) {
          setPagination((pagination) => ({
            ...pagination,
            current: 1,
            total: response.total,
          }));
        }

        setDataSource(mappedData);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token, isActive, query]);

  React.useEffect(() => {
    const socket = connect(user.access_token);
    socket.on(`ticket-updates-overview`, (data) => {
      setDataSource((dataSource) => {
        const index = dataSource.findIndex((el) => el.id === data.id);
        if (
          index === -1 &&
          data.status.toLowerCase() === "done" &&
          !isActive &&
          pagination.current === 1
        ) {
          // add ticket archived
          let newDataSource = dataSource.slice();
          newDataSource.push(dataMapper(data));
          newDataSource.sort(
            (a, b) =>
              b.hasIvantiResponse - a.hasIvantiResponse ||
              moment(b.updatedTimestamp).unix() -
                moment(a.updatedTimestamp).unix()
          );
          return newDataSource;
        } else if (
          index === -1 &&
          data.status.toLowerCase() !== "done" &&
          isActive &&
          pagination.current === 1
        ) {
          // add ticket active
          let newDataSource = dataSource.slice();
          newDataSource.push(dataMapper(data));
          newDataSource.sort(
            (a, b) =>
              b.hasIvantiResponse - a.hasIvantiResponse ||
              moment(b.updatedTimestamp).unix() -
                moment(a.updatedTimestamp).unix()
          );
          return newDataSource;
        } else if (index === -1) {
          // Ticket has no impact on the current view
          return dataSource;
        } else if (
          index >= 0 &&
          data.status.toLowerCase() === "done" &&
          isActive
        ) {
          // Remove ticket (an active ticket has been archived)
          return dataSource.filter((el) => el.id !== data.id);
        } else {
          let newDataSource = [...dataSource];
          newDataSource[index] = dataMapper(data);
          return newDataSource;
        }
      });
    });

    return () => socket.disconnect();
  }, [user.access_token, isActive, pagination]);

  function handleRowAction(record, rowIndex) {
    return {
      onClick: (event) => {
        history.push(`/agent/overview/${record.key}`);
      },
    };
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "4vw",
    },
    {
      title: "Prio",
      dataIndex: "priority",
      key: "priority",
      ellipsis: true,
      width: "4vw",
      sorter: true,
      render: (id, record) => {
        return (
          <Tooltip title={priorities[record.priority]}>
            <FlagFilled
              style={{ color: priorityColorMapper(record.priority) }}
            />
          </Tooltip>
        );
      },
    },
    {
      dataIndex: "hasIvantiResponse",
      key: "hasIvantiResponse",
      ellipsis: true,
      hidden: !dataSource.some((record) => record.hasIvantiResponse),
      width: "1.5vw",
      align: "center",
      render: (id, record) => {
        return record.hasIvantiResponse ? (
          <Space size="middle">
            <Badge count={<MessageFilled style={{ color: "#fa541c" }} />} />
          </Space>
        ) : (
          id
        );
      },
    },
    {
      title: "Onderwerp",
      dataIndex: "subject",
      key: "subject",
      ellipsis: true,
    },
    {
      title: "Klant",
      dataIndex: "customer",
      key: "customer",
      ellipsis: true,
    },
    {
      title: "Aanmaak datum",
      dataIndex: "creationTimestamp",
      key: "creationTimestamp",
      ellipsis: true,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["descend", "ascend"],
      render: (data) => formatDateTime(data),
    },
    {
      title: "Laatste bericht",
      dataIndex: "updatedTimestamp",
      key: "updatedTimestamp",
      ellipsis: true,
      sorter: true,
      showSorterTooltip: false,
      sortDirections: ["descend", "ascend"],
      render: (data) => formatDateTime(data),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        status = status.toUpperCase();
        let color = "";
        switch (status) {
          case "TO DO":
            color = "magenta";
            break;
          case "IN PROGRESS":
            color = "volcano";
            break;
          case "WAITING ON AVEVE":
            color = "lime";
            break;
          case "WAITING ON CUSTOMER":
            color = "gold";
            break;
          case "WAITING ON SUPPLIER":
            color = "orange";
            break;
          default:
            color = "green";
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
      ellipsis: true,
    },
    {
      title: "Verwerker",
      dataIndex: "agent",
      key: "agent",
      ellipsis: true,
    },
  ].filter((item) => !item.hidden);

  const onTableChange = (pagination, filters, sorter, extra) => {
    setPagination(pagination);
    const skip = (pagination.current - 1) * pagination.pageSize;
    const limit = pagination.pageSize;
    let sort = sorter.field;
    let sortDirection = sorter.order;
    if (sortDirection === undefined) {
      sort = undefined;
    }
    const newQuery = { ...query, skip, limit, sort, sortDirection };
    setQuery(newQuery);
  };

  const onHandleSearch = (query) => {
    const newQuery = { ...query, skip: 0 };
    setQuery(newQuery);
  };

  return (
    <Layout>
      <Card style={{ margin: "0px 0px 16px 0px", padding: "0px" }}>
        <Filters
          user={user}
          query={query}
          search={onHandleSearch}
          priorities={priorities}
        />
      </Card>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onRow={handleRowAction}
        onChange={onTableChange}
        rowClassName={(record) => {
          if(record.isUnreadAgent && record.status.toUpperCase() === "TO DO" && record.priority === 400){
            return "cashRegistryAlert"
          }
          else if(record.isUnreadAgent) {
            return "unread";
          }else if(record.status.toUpperCase() === "TO DO"){
            return "toDo"
          }
        }}
      />
    </Layout>
  );
}
