import React from "react";
import "./Header.css";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

export default function CustomHeader({ selectedKeys }) {
  const { t } = useTranslation("user");
  return (
    <Header>
      <div className="logo-container">
        <Link to="/user/overview">
          <img className="logo" src="/AveveLogo.png" alt="Aveve logo" />
        </Link>
      </div>
      <Menu theme="dark" mode="horizontal" selectedKeys={selectedKeys}>
        <Menu.Item key="createIssue">
          <Link to="/user/create-issue"> {t("header.newTicket")} </Link>
        </Menu.Item>
        <Menu.Item key="overview">
          <Link to="/user/overview"> {t("header.overview")} </Link>
        </Menu.Item>
      </Menu>
    </Header>
  );
}
