import { useLocation, useHistory, Route } from "react-router-dom";

import * as auth from "./common/utils/AuthProvider";
import Login from "./common/login/Login";
import Year from "./agent/statistics/Year";
import PreLoader from "./common/preLoader/PreLoader";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function UnAuthenticatedApp({ login }) {
  let query = useQuery();
  const history = useHistory();

  if (query.get("username") && query.get("password")) {
    login({ username: query.get("username"), password: query.get("password") });
    query.delete("username");
    query.delete("password");
    history.replace(query);
  } else if (query.get("token")) {
    auth.setToken({ access_token: query.get("token") });
    query.delete("token");
    history.replace(query);
    window.location.reload(false);
    return <PreLoader />;
  }

  return (
    <Switch>
      <Route exact path="/statistics">
        <div style={{ padding: "1vw 10vw" }}>
          <Year />
        </div>
      </Route>
      <Route path="*">
        <Login login={login} />
      </Route>
    </Switch>
  );
}
