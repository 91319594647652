import React from "react";
import {Form, Input, Switch} from "antd";
import { Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./IssueForm.css";
import { useLocalStorageState } from "../../common/utils/CustomHooks";
import { clientFormData } from "../../common/utils/ApiClient";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function IssueForm({ step, triggerNext, user }) {
  const [usernames, setUsernames] = useLocalStorageState(
    "create-issue:usernames",
    []
  );
  let history = useHistory();
  const { t } = useTranslation("user");
  const [fileList, setFileList] = React.useState([]);

  const handleEnter = (event) => {
    event.preventDefault();
    triggerNext();
  };

  const onFinish = (values) => {
    const { username, subject, content, cashRegisterAlert = false } = values;
    if (!usernames.includes(username.toLowerCase())) {
      setUsernames([...usernames, username.toLowerCase()]);
    }

    let formData = new FormData();
    formData.append("username", username);
    formData.append("subject", subject);
    formData.append("content", content);
    formData.append("cashRegisterAlert", cashRegisterAlert)
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    clientFormData("tickets", {
      data: formData,
      token: user.access_token,
    })
      .then(() => {
        history.push("/user/create-issue/success");
      })
      .catch((error) => console.log(error));
    // TODO add error handling
  };

  const onFinishFailed = (errorInfo) => {
    // TODO go to step with error
    console.log("Failed:", errorInfo);
  };

  const beforeUpload = (_, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  // Not completly sure why, but we need this
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  return (
    <div>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        {...formItemLayout}
      >
        <Form.Item
          hidden={step !== "username"}
          label={t("createIssue.form.username")}
          name="username"
          rules={[
            { required: true, message: t("createIssue.form.usernameRule") },
          ]}
        >
          <Input
            ref={(input) => input && input.focus()}
            onPressEnter={handleEnter}
          />
        </Form.Item>
        <Form.Item
          hidden={step !== "subject"}
          label={t("createIssue.form.subject")}
          name="subject"
          rules={[
            {
              required: true,
              message: t("createIssue.form.subjectRule"),
            },
          ]}
        >
          <Input
            ref={(input) => input && input.focus()}
            onPressEnter={handleEnter}
          />
        </Form.Item>
        <Form.Item
          hidden={step !== "content"}
          label={t("createIssue.form.description")}
          name="content"
          rules={[
            {
              required: true,
              message: t("createIssue.form.descriptionRule"),
            },
          ]}
        >
          <Input.TextArea
            ref={(input) => input && input.focus()}
            autoSize={{ minRows: 6 }}
          />
        </Form.Item>
        <Form.Item hidden={step !== "content"}
                   label={t("createIssue.form.cashRegisterAlert")}
                   name="cashRegisterAlert"
                   className="switch"
                   help={t("createIssue.form.alertHelp")}
                   valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          name="files"
          label={t("createIssue.form.upload")}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="upload"
          hidden={step !== "content"}
        >
          <Upload
            name="file"
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            filelist={fileList}
            multiple={true}
          >
            <Button icon={<UploadOutlined />}>
              {t("createIssue.form.uploadText")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 12, offset: 6 }}
          hidden={step !== "content"}
        >
          <Button type="primary" htmlType="submit">
            {t("createIssue.form.submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
