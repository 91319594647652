import React from "react";

import "./Login.css";

import { Alert, Card, Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};

export default function Login({ login }) {
  const { t } = useTranslation("login");
  const [error, setError] = React.useState(null);

  function onFinish(form) {
    login(form).catch(() => {
      setError(t("failedLogin"));
    });
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Card className="login-card">
      <h1>{t("login")}</h1>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label={t("username")} name="username">
          <Input />
        </Form.Item>

        <Form.Item label={t("password")} name="password">
          <Input.Password />
        </Form.Item>

        {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item> */}
        <Form.Item {...tailLayout} hidden={error ? false : true}>
          <Alert message={error} type="error" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit">
            {t("login")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
