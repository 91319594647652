import React from "react";
import { Upload, Button, Form, Input, Select, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// import "./IssueForm.css";
// import { useLocalStorageState } from "./Utils";
import { client, clientFormData } from "../../common/utils/ApiClient";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export default function CreateIssue({ user }) {
  const [users, setUsers] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const { t } = useTranslation("user");

  React.useEffect(() => {
    client("users/users-simple", { token: user.access_token }).then(
      (response) => {
        const users = response.map((user) => {
          return { label: user.username, value: user.id };
        });
        setUsers(users);
      }
    );
  }, [user.access_token]);

  const onFinish = (values) => {
    const { customer, subject, content } = values;
    let formData = new FormData();
    formData.append("username", user.username);
    formData.append("subject", subject);
    formData.append("content", content);
    formData.append("isUnread", true);
    formData.append("isUnreadAgent", false);
    formData.append("userId", customer);
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });
    clientFormData("tickets", {
      data: formData,
      token: user.access_token,
    }).then(
      Modal.success({
        title: "Geslaagd!",
        content: "Er is een nieuw ticket aangemaakt!",
        onOk() {
          window.location.reload(false);
        },
      })
    );
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const beforeUpload = (_, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  // Not completly sure why, but we need this
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        {...formItemLayout}
      >
        <Form.Item label="Winkelnaam" name="customer" required={true}>
          <Select
            showSearch
            // style={{ width: 200 }}
            placeholder="Kies een winkel"
            optionFilterProp="label"
            options={users}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>
        <Form.Item
          label={t("createIssue.form.subject")}
          name="subject"
          rules={[
            {
              required: true,
              message: t("createIssue.form.subjectRule"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("createIssue.form.description")}
          name="content"
          rules={[
            {
              required: true,
              message: t("createIssue.form.descriptionRule"),
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 6 }} />
        </Form.Item>
        <Form.Item
          name="files"
          label={t("createIssue.form.upload")}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="upload"
        >
          <Upload
            name="file"
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            filelist={fileList}
            multiple={true}
          >
            <Button icon={<UploadOutlined />}>
              {t("createIssue.form.uploadText")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            {t("createIssue.form.submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
