import React from "react";

import { Typography, message, Card } from "antd";

import SearchFilters from "./SearchFilters";
import SearchResults from "./SearchResults";
import SearchCustom from "./SearchCustom";

import { client } from "../../common/utils/ApiClient";
import { formatDateTime } from "../../common/utils/DateHelper";

const { Title, Paragraph } = Typography;

function dataMapper(data) {
  return data.map((d) => {
    return {
      key: d.id,
      id: d.id,
      subject: d.subject,
      customer: d.user,
      creationTimestamp: formatDateTime(d.createdAt),
      updatedTimestamp: formatDateTime(
        d.lastMessage ? d.lastMessage : d.createdAt
      ),
      status: d.status,
      agent: d.agent ? d.agent : null,
      isUnreadAgent: d.isUnreadAgent,
    };
  });
}

const tabList = [
  {
    key: "filters",
    tab: "Filters",
  },
  {
    key: "custom",
    tab: "Custom filters",
  },
];

export default function Search({ user }) {
  const [queryString, setQueryString] = React.useState("");
  const [dataSource, setDataSource] = React.useState();
  const [key, setKey] = React.useState("filters");

  React.useEffect(() => {
    if (!queryString || queryString === "") {
      setDataSource(null);
      return;
    }
    client(`tickets?${queryString}`, { token: user.access_token })
      .then((response) => {
        if (!response || response.length === 0) {
          setDataSource(null);
          message.success(
            "Er zijn geen resultaten gevonden voor de opgegeven filters"
          );
          return;
        }
        setDataSource(dataMapper(response));
      })
      .catch((response) =>
        message.error(
          "Er is iets mis gegaan met uw zoek query. Wijzig iets en probeer het nog eens!",
          2
        )
      );
  }, [user.access_token, queryString]);

  const content = {
    filters: <SearchFilters user={user} setQueryString={setQueryString} />,
    custom: (
      <SearchCustom
        user={user}
        setDataSource={setDataSource}
        dataMapper={dataMapper}
      />
    ),
  };

  return (
    <>
      <Title>Zoeken</Title>
      <Paragraph>
        Hiermee kan je geavanceerd zoeken. Je kan 1 veld invullen of je kan alle
        velden invullen. Je kunt het dus zo specifiek of zo algemeen als nodig
        is zoeken.
      </Paragraph>
      <Card
        style={{ margin: "8px 0px" }}
        tabList={tabList}
        activeTabKey={key}
        onTabChange={(key) => setKey(key)}
      >
        {content[key]}
      </Card>

      <SearchResults dataSource={dataSource} />
    </>
  );
}
