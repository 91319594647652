import React from "react";
import { ColumnChart, ColumnChartProps } from "@opd/g2plot-react";

import { client } from "../../../common/utils/ApiClient";

export default function OpenGraph({ user }) {
  const [graphData, setGraphData] = React.useState([]);
  const chartRef = React.useRef();

  React.useEffect(() => {
    client(`statistics/tickets/open/graph-values`, {
      token: user.access_token,
    })
      .then((response) => setGraphData(response))
      .catch(() => setGraphData([]));
  }, [user.access_token]);

  const config: ColumnChartProps = {
    data: graphData,
    isStack: true,
    xField: "week",
    xAxis: {
      label: {
        formatter: (text) => (text === "13" ? ">13" : text),
      },
    },
    yField: "value",
    seriesField: "type",
    label: {
      position: "middle", // 'top', 'bottom', 'middle'
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    color: ({ type }) => {
      if (type === "Software") {
        return "#61DDAA";
      } else if (type === "Hardware") {
        return "#5B8FF9";
      } else if (type === "LSNAV") {
        return "#F6BD16";
      } else if (type === "O365") {
        return "#6F5EF9";
      }
      return "#65789B";
    },
  };

  return (
    <>
      <ColumnChart {...config} chartRef={chartRef} />
    </>
  );
}
