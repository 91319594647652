import React from "react";
import {
  Form,
  Input,
  Button,
  Alert,
  Modal,
  Divider,
  Typography,
  message,
} from "antd";

import { client } from "../../common/utils/ApiClient";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export default function Profile({ user }) {
  const [error, setError] = React.useState(null);

  const onFinish = (values) => {
    const data = { oldPassword: values.old, newPassword: values.password };
    client("users/me", { data, token: user.access_token, method: "PUT" })
      .then(() =>
        Modal.success({
          title: "Geslaagd!",
          content: "Wachtwoord succesvol gewijzigd!",
          onOk() {
            window.location.reload(false);
          },
        })
      )
      .catch(() => {
        setError(
          "Het opgegeven oud wachtwoord komt niet overeen met het huidige wachtwoord"
        );
      });
  };

  const sendTestEmail = () => {
    client("util/test-email", { token: user.access_token, method: "POST" })
      .then((response) => message.success(response.msg))
      .catch(() => {
        message.error(
          "Er is iets mis gegaan bij het versturen van de test mail."
        );
      });
  };

  return (
    <div>
      <div style={{ width: "50vw", margin: "auto" }}>
        <Typography.Title>Profiel</Typography.Title>
        <Typography.Title level={2}>Test email notificatie</Typography.Title>
        <Typography.Paragraph>
          Met de onderstaande knop kan je een test mail sturen naar de
          notificatie mailbox. (posalert)
        </Typography.Paragraph>
        <Button type="primary" onClick={sendTestEmail}>
          Stuur test mail
        </Button>
      </div>
      <Divider />
      <div style={{ width: "50vw", margin: "auto" }}>
        <Typography.Title level={2}>Wachtwoord veranderen</Typography.Title>
        <Form
          {...formItemLayout}
          name="register"
          onFinish={onFinish}
          initialValues={{
            residence: ["zhejiang", "hangzhou", "xihu"],
            prefix: "86",
          }}
          scrollToFirstError
        >
          <Form.Item
            name="old"
            label="Oud Wachtwoord"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="password"
            label="Nieuw Wachtwoord"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Bevestig Wachtwoord"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailFormItemLayout} hidden={error ? false : true}>
            <Alert message={error} type="error" />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Wijzig
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
