import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import User from "./user/User";
import Agent from "./agent/Agent";
import NotFound from "./common/statusPages/NotFound";

export default function AuthenticatedApp({ user, logout }) {
  return user.isAgent ? (
    <Switch>
      <Redirect exact path="/" to="/agent" />
      <Route path="/agent">
        <Agent user={user} logout={logout} />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  ) : (
    <Switch>
      <Redirect exact path="/" to="/user" />
      <Route path="/user">
        <User user={user} logout={logout} />
      </Route>
      <Route path="*" component={NotFound} />
    </Switch>
  );
}
