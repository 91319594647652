import React from "react";

import { Link, Route, Switch, Redirect } from "react-router-dom";

import { Button, Layout, Modal, Typography } from "antd";

import CreateIssue from "./createIssue/CreateIssue";
import Overview from "./overview/Overview";
import ResultSuccess from "./resultSuccess/ResultSuccess";
import DetailView from "./detailView/DetailView";
import { client } from "../common/utils/ApiClient";

export default function User({ user, logout }) {
  React.useEffect(() => {
    client("welcome-message/latest", { token: user.access_token }).then(
      (response) => {
        if (!response) {
          return;
        }
        Modal.info({
          title: response.title,
          content: (
            <Typography.Text style={{ whiteSpace: "pre-line" }}>
              {response.content}
            </Typography.Text>
          ),
        });
      }
    );
  }, [user.access_token]);

  return (
    <>
      <Layout className="layout">
        <Switch>
          <Redirect exact path="/user" to="/user/create-issue" />
          <Route exact path="/user/create-issue">
            <CreateIssue user={user} />
          </Route>
          <Route path="/user/create-issue/success" component={ResultSuccess} />
          <Route exact path="/user/overview">
            <Overview user={user} />
          </Route>
          <Route path="/user/overview/:ticketId">
            <DetailView user={user} />
          </Route>
          {/* Easy hidden way to logout if necessary (support reasons) */}
          <Route path="/user/logout">
            <Button style={{ width: 250 }} type="primary" onClick={logout}>
              <Link to="/"> Click here to logout </Link>
            </Button>
          </Route>
        </Switch>
      </Layout>
    </>
  );
}
