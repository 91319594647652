// import { queryCache } from "react-query";
import axios from "axios";
import * as sentry from "@sentry/react";
// import * as auth from "./AuthProvider";
const apiURL = process.env.REACT_APP_API_URL;

async function client(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {}
) {
  const config = {
    url: `${apiURL}/api/${endpoint}`,
    method: data ? "POST" : "GET",
    data: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  return axios(config)
    .then(async (response) => {
      return await response.data;
    })
    .catch(async (error) => {
      if (error.response.status === 401) {
        //   queryCache.clear();
        // await auth.logout();
        // refresh the page for them
        // window.location.assign(window.location);
        return Promise.reject({
          message: "Please re-authenticate.",
          status: error.response.status,
        });
      } else if (error.response.status === 404) {
        return Promise.reject({
          message: "Resource not found",
          status: error.response.status,
        });
      } else {
        console.log(error.response);
        throw error;
      }
    });
}

async function clientFormData(
  endpoint,
  { data, token, headers: customHeaders, ...customConfig } = {}
) {
  const config = {
    url: `${apiURL}/api/${endpoint}`,
    method: "POST",
    data: data,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": "multipart/form-data",
      ...customHeaders,
    },
    ...customConfig,
  };
  return axios(config)
    .then(async (response) => {
      return await response.data;
    })
    .catch(async (error) => {
      sentry.captureException(error);
      if (error.response.status === 401) {
        //   queryCache.clear();
        // await auth.logout();
        // refresh the page for them
        // window.location.assign(window.location);
        return Promise.reject({ message: "Please re-authenticate." });
      } else if (error.response.status === 404) {
        return Promise.reject({ message: "Resource not found" });
      } else {
        console.log(error.response);
        throw error;
      }
    });
}

export { client, clientFormData };
