import React from "react";
import moment from "moment";
import "moment/locale/nl";
import { ColumnChart, ColumnChartProps } from "@opd/g2plot-react";
import { each, groupBy } from "@antv/util";

import { client } from "../../../common/utils/ApiClient";

export default function TicketsPerMonthGraph({ year }) {
  const [graphData, setGraphData] = React.useState([]);
  const [annotations, setAnnotations] = React.useState([]);
  const chartRef = React.useRef();

  React.useEffect(() => {
    if (!year) {
      return;
    }
    client(`statistics/year/tickets?year=${year}`)
      .then((response) => {
        setGraphData(response);
        const annotations = [];
        each(groupBy(response, "month"), (values, k) => {
          const value = values.reduce((a, b) => a + b.value, 0);
          annotations.push({
            type: "text",
            position: [k, value],
            content: `${value}`,
            style: {
              textAlign: "center",
              fontSize: 14,
              fill: "rgba(255,255,255,0.85)",
            },
            offsetY: -10,
          });
        });
        setAnnotations(annotations);
      })
      .catch(() => setGraphData([]));
  }, [year]);

  const config: ColumnChartProps = {
    data: graphData,
    isStack: true,
    xField: "month",
    xAxis: {
      label: {
        formatter: (text) => moment(text).locale("nl").format("MMMM"),
      },
    },
    yField: "value",
    seriesField: "type",
    label: {
      position: "middle", // 'top', 'bottom', 'middle'
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
    color: ({ type }) => {
      if (type === "Software") {
        return "#61DDAA";
      } else if (type === "Hardware") {
        return "#5B8FF9";
      } else if (type === "LSNAV") {
        return "#F6BD16";
      } else if (type === "O365") {
        return "#6F5EF9";
      }
      return "#65789B";
    },
    annotations,
  };

  return (
    <>
      <ColumnChart {...config} chartRef={chartRef} />
    </>
  );
}
