import React from "react";

import { Layout } from "antd";
import CreateWelcomeMessage from "./CreateWelcomeMessage";
import CurrentWelcomeMessage from "./CurrentWelcomeMessage";

const { Content } = Layout;

export default function WelcomeMessage({ user }) {
  const [welcomeMessage, setWelcomeMessage] = React.useState();

  return (
    <Content style={{ margin: "0 16px" }}>
      <CreateWelcomeMessage user={user} setWelcomeMessage={setWelcomeMessage} />
      <CurrentWelcomeMessage
        user={user}
        welcomeMessage={welcomeMessage}
        setWelcomeMessage={setWelcomeMessage}
      />
    </Content>
  );
}
