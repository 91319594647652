import React from "react";
import { Layout, Menu } from "antd";
import {
  HistoryOutlined,
  FileTextOutlined,
  TeamOutlined,
  UserOutlined,
  PlusOutlined,
  LogoutOutlined,
  AreaChartOutlined,
  NotificationTwoTone,
  InfoCircleOutlined,
  SearchOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

import "./LeftSider.css";
import { useLocalStorageState } from "../common/utils/CustomHooks";

const { Sider } = Layout;

export default function LeftSider({ logout }) {
  const [collapsed, setCollapsed] = React.useState(false);
  const [sound, setSound] = useLocalStorageState("sound", true);

  function onCollapse(collapsed) {
    setCollapsed(collapsed);
  }

  const toggleSound = () => {
    setSound(!sound);
    window.location.reload();
  };

  return (
    <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
      <div>
        <img
          className={collapsed ? "logo-small" : "logo-normal"}
          src="/AveveLogo.png"
          alt="Aveve logo"
        />
      </div>
      <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline" items={[
        {
          label: (<Link to="/agent/overview"> Tickets </Link>),
          key: "overview",
          icon: (<FileTextOutlined />),
        },
        {
          label: (<Link to="/agent/archive"> Afgehandeld </Link>),
          key: "archive",
          icon: (<HistoryOutlined />),
        },
        {
          label: (<Link to="/agent/search"> Zoeken </Link>),
          key: "search",
          icon: (<SearchOutlined />),
        },
        {
          label: (<Link to="/agent/create-issue"> Maak een ticket </Link>),
          key: "createIssue",
          icon: (<PlusOutlined />),
        },
        {
          label: "Statistieken",
          key: "statistics",
          icon: (<AreaChartOutlined />),
          children: [
            {
              label: (<Link to="/agent/statistics/actual"> Actueel </Link>),
              key: "Actual",
            },
            {
              label: (<Link to="/agent/statistics/period"> Voor een periode </Link>),
              key: "Period",
            },
            {
              label: (<Link to="/agent/statistics/year"> Voor een jaar </Link>),
              key: "Year",
            }
          ]
        },
        {
          label: (<Link to="/agent/timesheets"> Timesheets </Link>),
          key: "timesheets",
          icon: (<DashboardOutlined />),
        },
        {
          label: (<Link to="/agent/customers"> Klanten </Link>),
          key: "customers",
          icon: (<TeamOutlined />),
        },
        {
          label: (<Link to="/agent/welcome-message"> Welkom bericht </Link>),
          key: "welcome-message",
          icon: (<InfoCircleOutlined />),
        },
        {
          label: (<Link to="/agent/profile"> Profiel </Link>),
          key: "profile",
          icon: (<UserOutlined />),
        },
        {
          label: (<div>Zet geluid {sound ? "uit" : "aan"}</div>),
          key: "sound",
          icon: (<NotificationTwoTone twoToneColor={sound ? "#52c41a" : "#eb2f96"} />),
          onClick: toggleSound
        },
        {
          label: "Uitloggen",
          key: "logout",
          icon: (<LogoutOutlined />),
          onClick: logout
        }
      ]}  />
    </Sider>
  );
}
