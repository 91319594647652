import React from "react";

import { useParams } from "react-router-dom";
import { Typography, message, Skeleton, Card } from "antd";

import { client } from "../../common/utils/ApiClient";
import CustomerInfo from "./CustomerInfo";
import CustomerProducts from "./CustomerProducts";
import axios from "axios";
import CompanyNote from "./CompanyNote";

const { Title } = Typography;

export default function CustomerDetail({ user }) {
  const { customerId } = useParams();
  const [customer, setCustomer] = React.useState();
  const [products, setProducts] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  // Soley for triggering rerender after update request
  const [update, setUpdate] = React.useState(false);

  React.useEffect(() => {
    const requests = [
      client(`users/${customerId}`, { token: user.access_token }),
      client(`users/${customerId}/products?getAll=true`, {
        token: user.access_token,
      }),
    ];
    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          setCustomer(responses[0]);
          setProducts(responses[1]);
          setIsLoading(false);
        })
      )
      .catch((response) =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token, customerId, update]);

  const updateCompanyInfo = (value, field) => {
    const data = { [field]: value };
    client(`company/${customer.company.id}`, {
      data,
      token: user.access_token,
      method: "PATCH",
    })
      .then(() => {
        const newCustomer = { ...customer };
        newCustomer.company[field] = value;
        setCustomer(newCustomer);
        message.success(
          `Het veld ${field} is geupdate met een nieuwe waarde ${value}.`,
          5
        );
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het updaten van de data.")
      );
  };

  const updateUserInfo = (value, field) => {
    const data = { [field]: value };
    client(`users/${customer.id}`, {
      data,
      token: user.access_token,
      method: "PATCH",
    })
      .then(() => {
        const newCustomer = { ...customer };
        newCustomer[field] = value;
        setCustomer(newCustomer);
        message.success(
          `Het veld ${field} is geupdate met een nieuwe waarde ${value}.`,
          5
        );
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het updaten van de data.")
      );
  };

  const addNewProductNote = (productNote, productId) => {
    const data = { content: productNote, productId };
    client("product-notes", {
      token: user.access_token,
      data,
    })
      .then(() => {
        message.success(`Nieuwe notitie toegevoegd.`);
        setUpdate(!update);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  };

  const disableProduct = (productId) => {
    const data = { isActive: false };
    client(`products/${productId}`, {
      token: user.access_token,
      data,
      method: "PUT",
    })
      .then(() => {
        message.success(
          `Product met id ${productId} is op non-actief gezet.`,
          3
        );
        setUpdate(!update);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  };

  return isLoading ? (
    <>
      <Skeleton title />
      <Card>
        <Skeleton />
      </Card>
      <Card>
        <Skeleton />
      </Card>
      <Card>
        <Skeleton />
      </Card>
    </>
  ) : (
    <>
      <Title>{customer?.username}</Title>
      <CustomerInfo
        customer={customer}
        updateCompanyInfo={updateCompanyInfo}
        updateUserInfo={updateUserInfo}
      />
      <CompanyNote
        user={user}
        note={customer.company.note?.content}
        companyId={customer.company.id}
      />
      <CustomerProducts
        products={products}
        addNewProductNote={addNewProductNote}
        disableProduct={disableProduct}
      />
    </>
  );
}
