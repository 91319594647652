import React from "react";
import moment from "moment";
import { Typography, Row, Col, List } from "antd";
import { ColumnChart, ColumnChartProps } from "@opd/g2plot-react";

import { client } from "../../../common/utils/ApiClient";
import { formatDateTime } from "../../../common/utils/DateHelper";

const { Text } = Typography;

export default function HourGraph({ user, range }) {
  const [graphData, setGraphData] = React.useState([]);
  const [ranking, setRanking] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const chartRef = React.useRef();

  React.useEffect(() => {
    if (!range) {
      return;
    }
    client(
      `statistics/time-log/graph-values?start=${formatDateTime(
        range[0],
        "YYYY-MM-DD"
      )}&end=${formatDateTime(range[1], "YYYY-MM-DD")}`,
      {
        token: user.access_token,
      }
    )
      .then((response) => setGraphData(response))
      .catch(() => setGraphData([]));

    client(
      `statistics/time-log/ranking?start=${formatDateTime(
        range[0],
        "YYYY-MM-DD"
      )}&end=${formatDateTime(range[1], "YYYY-MM-DD")}`,
      {
        token: user.access_token,
      }
    )
      .then((response) => setRanking(response))
      .catch(() => setRanking([]));
    setLoading(false);
  }, [user.access_token, range]);

  const parseDurationHour = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    const time = moment.utc(duration.asMilliseconds()).format(":mm");
    return `${Math.floor(duration.asHours())}${time}`;
  };

  const config: ColumnChartProps = {
    data: graphData,
    isStack: true,
    xField: "day",
    xAxis: {
      label: {
        formatter: (text) => text.substring(5),
      },
    },
    yField: "value",
    seriesField: "type",
    label: {
      position: "middle", // 'top', 'bottom', 'middle'
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
      formatter: (object) => parseDurationHour(object.value),
    },
    tooltip: {
      formatter: (object) => {
        return { name: object.type, value: parseDurationHour(object.value) };
      },
    },
    color: ({ type }) => {
      if (type === "Software") {
        return "#61DDAA";
      } else if (type === "Hardware") {
        return "#5B8FF9";
      } else if (type === "LSNAV") {
        return "#F6BD16";
      } else if (type === "O365") {
        return "#6F5EF9";
      }
      return "#65789B";
    },
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={18}>
          <ColumnChart {...config} chartRef={chartRef} />
        </Col>
        <Col span={6}>
          <List
            split={false}
            header={<Text>Top 10</Text>}
            dataSource={ranking}
            loading={loading}
            renderItem={(item) => (
              <List.Item style={{ padding: "4px 16px" }}>
                <List.Item.Meta title={<Text>{item.username}</Text>} />
                <Text>{parseDurationHour(item.value)}</Text>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
