import React from "react";

import { Card, Table, Badge, Typography, Space, Button, Modal } from "antd";

// Not ideal, but removes duplicate code
import { InputModal } from "../detailView/Modals";
import { formatDateTime } from "../../common/utils/DateHelper";
const { Text } = Typography;

const renderBooleanColumn = (text, record, index) => {
  return (
    <Badge
      status={record.isActive ? "success" : "error"}
      text={record.isActive ? <Text>Ja</Text> : <Text>Nee</Text>}
    />
  );
};

const productNotesTable = (record) => {
  const columns = [
    {
      title: "Datum",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => formatDateTime(value),
    },
    { title: "Content", dataIndex: "content", key: "content" },
    {
      title: "Aangemaakt door",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (value, record, index) => value.username,
    },
  ];
  // for unique key prop warning
  const productNotes = record.productNotes.map((note) => ({
    ...note,
    key: note.id,
  }));
  return <Table columns={columns} dataSource={productNotes} />;
};

export default function CustomerProducts({
  products,
  addNewProductNote,
  disableProduct,
}) {
  const [selectedProductId, setSelectedProductId] = React.useState();
  const [productNote, setProductNote] = React.useState();
  const [isVisibleNewProductNote, setIsVisibleNewProductNote] = React.useState(
    false
  );

  const handleNewProductNoteAction = (id) => {
    setSelectedProductId(id);
    setIsVisibleNewProductNote(true);
  };

  const handleDisableProduct = (id) => {
    Modal.confirm({
      title: "Ben je zeker?",
      content:
        "Ben je zeker dat je dit product op non-actief wilt zetten? Het zal dan niet langer getoont worden in het product overzicht binnen in een ticket. Je kan non-actieve producten wel nog steeds bekijken in het algemene overzicht van de klant.",
      onOk: () => disableProduct(id),
    });
  };

  const handleOk = () => {
    addNewProductNote(productNote, selectedProductId);
    setSelectedProductId(null);
    setIsVisibleNewProductNote(false);
  };

  const handleCancel = () => {
    setSelectedProductId(null);
    setIsVisibleNewProductNote(false);
  };

  const columns = [
    { title: "Beschrijving", dataIndex: "description", key: "description" },
    { title: "Serienummer", dataIndex: "serialNumber", key: "serialNumber" },
    { title: "Aankoop datum", dataIndex: "purchaseDate", key: "purchaseDate" },
    {
      title: "Is actief",
      dataIndex: "isActive",
      key: "isActive",
      render: renderBooleanColumn,
    },
    {
      title: "Acties",
      key: "action",
      render: (text, record, index) => {
        return (
          <Space size="middle">
            <Button
              type="link"
              disabled={!record.isActive}
              onClick={() => handleNewProductNoteAction(record.id)}
            >
              Nieuwe notitie
            </Button>
            <Button
              type="link"
              disabled={!record.isActive}
              onClick={() => handleDisableProduct(record.id)}
              danger
            >
              Deactiveer
            </Button>
          </Space>
        );
      },
    },
  ];

  // for unique key prop warning
  products = products.map((product) => ({ ...product, key: product.id }));
  return (
    <Card title="Product informatie" style={{ marginTop: 16 }}>
      <Table
        dataSource={products}
        columns={columns}
        expandable={{
          expandedRowRender: productNotesTable,
          rowExpandable: (record) => record?.productNotes.length !== 0,
        }}
      />
      <InputModal
        isModalOpen={isVisibleNewProductNote}
        handleOk={handleOk}
        handleCancel={handleCancel}
        setInput={setProductNote}
        title="Nieuwe notitie aanmaken"
        body="Geef hier uw opmerking in:"
      />
    </Card>
  );
}
