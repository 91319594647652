import React from "react";
import moment from "moment";

import {
  Form,
  Input,
  Button,
  Modal,
  Typography,
  Space,
  DatePicker,
  message,
} from "antd";

import { client } from "../../common/utils/ApiClient";
import { formatDateTime } from "../../common/utils/DateHelper";

const { Title } = Typography;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

export default function CreateWelcomeMessage({ user, setWelcomeMessage }) {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const { title, content, showUntil } = values;
    const data = {
      title,
      content,
      showUntil: showUntil ? formatDateTime(showUntil, "YYYY-MM-DD") : null,
    };
    client("welcome-message", { data, token: user.access_token })
      .then((response) => {
        setWelcomeMessage(response);
        message.success("Er is een nieuw welkomsbericht aangemaakt!");
        form.resetFields();
      })
      .catch((err) => {
        Modal.error({
          title: "Oeps!",
          content: "Er is iets mis gegaan, probeer het later nog eens",
        });
      });
  };

  const preview = () => {
    Modal.info({
      title: form.getFieldValue("title"),
      content: form.getFieldValue("content"),
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today
    return current < moment().startOf("day");
  };

  return (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Form {...formItemLayout} onFinish={onFinish} form={form}>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Title>Maak een welkomstbericht</Title>
        </Form.Item>
        <Form.Item
          label="Titel"
          name="title"
          required
          rules={[
            {
              required: true,
              message: "Een titel is verplicht",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bericht"
          name="content"
          required
          rules={[
            {
              required: true,
              message: "Een bericht is verplicht",
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 6 }} />
        </Form.Item>
        <Form.Item label="Toon bericht tot en met" name="showUntil">
          <DatePicker disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Space>
            <Button onClick={preview}>Voorbeeld</Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
