import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.css";
import { client } from "./common/utils/ApiClient";
import * as auth from "./common/utils/AuthProvider";
import { useAsync } from "./common/utils/CustomHooks";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import PreLoader from "./common/preLoader/PreLoader";
import ErrorPage from "./common/statusPages/ErrorPage";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";

async function getUser() {
  let user = null;

  const token = await auth.getToken();
  if (token) {
    user = await client("users/me", { token });
    auth.setToken(user);
    Sentry.setUser({
      id: user.id,
      username: user.username,
      email: user.email,
      ip_address: "{{auto}}",
    });
  }

  return user;
}

function App() {
  const [user, setUser] = React.useState(null);
  // userReady because the page renders before executing the useEffect to update user
  // This results in login flash and accidental logins through url
  const [userReady, setUserReady] = React.useState(false);
  const { i18n } = useTranslation();
  let { data, status, error, run } = useAsync({
    status: "pending",
  });

  // TODO not ideal?? memoise??
  React.useEffect(() => {
    if (status === "pending") {
      return;
    }
    setUser(data);
    setUserReady(true);
    if (data && !data.isAgent && data.company.language !== i18n.language) {
      i18n.changeLanguage(data.company.language);
    } else if (data && data.isAgent && i18n.language !== "nl") {
      i18n.changeLanguage("nl");
    }
  }, [status, data, i18n]);

  React.useEffect(() => {
    run(getUser());
  }, [run]);

  const login = (form) =>
    auth.login(form).then((u) => {
      setUser(u);
      setUserReady(true);
      if (u.company) {
        i18n.changeLanguage(u.company.language);
      }
    });
  const logout = () => {
    auth.logout();
    setUser(null);
    Sentry.configureScope((scope) => scope.setUser(null));
  };

  if (status === "pending" || !userReady) {
    return <PreLoader />;
  }
  return (
    <Router>
      {user ? (
        <Sentry.ErrorBoundary fallback={ErrorPage}>
          <AuthenticatedApp user={user} logout={logout} />
        </Sentry.ErrorBoundary>
      ) : (
        <UnauthenticatedApp login={login} loginError={error} />
      )}
    </Router>
  );
}

export default App;
