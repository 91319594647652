import { Row, Col, Card, Typography } from "antd";

import OpenGraph from "./graphs/OpenGraph";
import StatusCard from "./cards/StatusCard";

export default function Actual({ user }) {
  return (
    <>
      <Typography.Title level={2}>Actueel</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Open ticketten per status">
            <StatusCard user={user} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Een overzicht van open ticketten per week">
            <OpenGraph user={user} />
          </Card>
        </Col>
      </Row>
    </>
  );
}
