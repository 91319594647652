import React from "react";
import { Card, Space, Statistic, Typography, List } from "antd";

import { client } from "../../../common/utils/ApiClient";
import { formatDateTime } from "../../../common/utils/DateHelper";

const { Text } = Typography;

export default function InfoCard({
  user,
  range,
  icon,
  title,
  endpoint,
  totalEndpoint,
  parser,
}) {
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!endpoint) {
      return;
    }
    client(
      `${endpoint}?start=${formatDateTime(
        range[0],
        "YYYY-MM-DD"
      )}&end=${formatDateTime(range[1], "YYYY-MM-DD")}`,
      { token: user.access_token }
    )
      .then((response) => {
        setData(response);
        if (!totalEndpoint) {
          setTotal(
            response.reduce(
              (a, b) => ({
                value: a.value + b.value,
              }),
              { value: 0 }
            ).value
          );
        }
      })
      .catch(() => {
        setData([]);
        setTotal(0);
      });
    if (!totalEndpoint) {
      setLoading(false);
      return;
    }
    client(
      `${totalEndpoint}?start=${formatDateTime(
        range[0],
        "YYYY-MM-DD"
      )}&end=${formatDateTime(range[1], "YYYY-MM-DD")}`,
      { token: user.access_token }
    )
      .then((response) => {
        setTotal(response);
      })
      .catch(() => setTotal(0));
    setLoading(false);
  }, [user.access_token, endpoint, totalEndpoint, range]);

  return (
    <Card>
      <Space direction="vertical">
        <Statistic
          title={title}
          prefix={icon}
          value={parser ? parser(total) : total}
          loading={loading}
        />
        <List
          size="small"
          split={false}
          dataSource={data}
          loading={loading}
          renderItem={(item) => (
            <List.Item style={{ padding: "4px 16px" }}>
              <Text>
                {item.type}: {parser ? parser(item.value) : item.value}
              </Text>
            </List.Item>
          )}
        />
      </Space>
    </Card>
  );
}
