import React from "react";
import { Card } from "antd";

import TicketGraph from "../graphs/TicketGraph";
import HourGraph from "../graphs/HourGraph";
import ClosedGraph from "../graphs/ClosedGraph";

const tabList = [
  {
    key: "tickets",
    tab: "Ticketten",
  },
  {
    key: "hoursWorked",
    tab: "Uren gewerkt",
  },
  {
    key: "closedTickets",
    tab: "Gesloten ticketten",
  },
];

export default function GraphCard({ user, range }) {
  const [key, setKey] = React.useState("tickets");

  const content = {
    tickets: <TicketGraph user={user} range={range} />,
    hoursWorked: <HourGraph user={user} range={range} />,
    closedTickets: <ClosedGraph user={user} range={range} />,
  };

  return (
    <Card
      style={{ width: "100%" }}
      tabList={tabList}
      activeTabKey={key}
      onTabChange={(key) => setKey(key)}
    >
      {content[key]}
    </Card>
  );
}
