import React from "react";

import { Layout, message } from "antd";
import { useParams } from "react-router-dom";
import connect from "../../common/utils/SocketClient";

import { client } from "../../common/utils/ApiClient";
import TicketView from "../../common/ticketView/TicketView";
import NotFound from "../../common/statusPages/NotFound";
import CustomHeader from "../Header";

const { Content } = Layout;

export default function DetailView({ user }) {
  const { ticketId } = useParams();
  const [ticket, setTicket] = React.useState(null);
  const [notFound, setNotFound] = React.useState(false);

  React.useEffect(() => {
    client(`tickets/${ticketId}`, { token: user.access_token })
      .then((data) => {
        setTicket(data);
      })
      .catch((response) => {
        if (response.status === 404) {
          setNotFound(true);
        } else {
          message.error("Er is iets misgelopen met het ophalen van de data.");
        }
      });
  }, [user.access_token, ticketId]);

  React.useEffect(() => {
    const socket = connect(user.access_token);
    socket.on("connect", (data) => {});
    socket.on(`ticket-${ticketId}`, (data) => {
      delete data.comments;
      setTicket((ticket) => ({ ...ticket, data }));
    });
    socket.on(`user-ticket-${ticketId}-comments`, (data) => {
      setTicket((ticket) => {
        let newComments = ticket.comments.slice();
        newComments.push(data);
        return { ...ticket, comments: newComments };
      });
    });
    return () => {
      socket.emit("read-ticket", ticketId, user.isAgent);
      socket.disconnect();
    };
  }, [ticketId, user.access_token, user.isAgent]);

  return notFound ? (
    <NotFound />
  ) : (
    <>
      <CustomHeader />
      <Content style={{ padding: "0 200px" }}>
        <TicketView user={user} ticket={ticket} />
      </Content>
    </>
  );
}
