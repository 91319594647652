import React from "react";
import { useTranslation } from "react-i18next";
import { Form, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";

import "./TicketView.css";
import Reply from "./Reply";
import CommentList from "./CommentList";
import { client, clientFormData } from "../utils/ApiClient";
import { formatDateTime } from "../utils/DateHelper";
import PreLoader from "../preLoader/PreLoader";

export default function TicketView({ user, ticket, username, handleSend }) {
  const [comments, setComments] = React.useState([]);
  const [form] = Form.useForm();
  const [fileList, setFileList] = React.useState([]);
  const { t } = useTranslation("user");

  const handleSubmit = (values) => {
    const { content, hidden = false } = values;
    if (!content) {
      return;
    }
    let formData = new FormData();
    formData.append("username", username ? username : ticket.username);
    formData.append("content", content);
    formData.append("isHidden", hidden);
    formData.append("ticketId", ticket.id);
    fileList.forEach((file) => {
      formData.append("files[]", file);
    });

    clientFormData("comments", { token: user.access_token, data: formData })
      .then(() => {
        form.resetFields();
        if (handleSend) {
          handleSend();
        }
      })
      .catch((error) => {
        message.error("Er is iets misgelopen met het ophalen van de data.");
      });
  };

  React.useEffect(() => {
    if (!ticket) {
      return;
    }
    const handleClick = (id, fileName) => (event) => {
      message.info("Het downloaden is gestart...");
      client(`attachments/${id}/download`, {
        token: user.access_token,
        responseType: "blob",
      })
        .then((response) => fileDownload(response, fileName))
        .catch(() => message.error("De file is niet langer beschikbaar."));
    };

    const generateActions = (attachments) => {
      return attachments.map((attachment) => {
        return (
          <span
            key={attachment.id}
            onClick={handleClick(attachment.id, attachment.fileName)}
          >
            <DownloadOutlined />
            <span> {attachment.fileName}</span>
          </span>
        );
      });
    };

    const baseComment = {
      actions: generateActions(ticket.attachments),
      author: ticket.username,
      content: <p>{ticket.content}</p>,
      datetime: formatDateTime(ticket.createdAt),
    };
    const comments = ticket.comments.map((element) => {
      return {
        actions: generateActions(element.attachments),
        author: element.username,
        content: <p>{element.content}</p>,
        datetime: formatDateTime(element.createdAt),
        style: { backgroundColor: element.isHidden ? "#2b1d11" : "" },
      };
    });
    setComments([baseComment, ...comments]);
  }, [ticket, user.access_token]);

  return ticket ? (
    <>
      <h1>
        {t("ticketView.subject")}: {ticket.subject}
      </h1>
      <CommentList comments={comments} />
      <Reply
        isArchived={ticket.status.toUpperCase() === "DONE"}
        form={form}
        onFinish={handleSubmit}
        fileList={fileList}
        setFileList={setFileList}
        agent={user.isAgent}
      />
    </>
  ) : (
    <PreLoader />
  );
}
