import React from "react";
import { AutoComplete, Table, Input } from "antd";
import { useHistory } from "react-router-dom";

import { client } from "../../common/utils/ApiClient";

export default function Customers({ user }) {
  const history = useHistory();
  const [data, setData] = React.useState();
  const [dataSource, setDataSource] = React.useState();

  React.useEffect(() => {
    client("users/users", { token: user.access_token }).then((response) => {
      const users = response.map(({ id, username, email, company }) => {
        return {
          key: id,
          username,
          email,
          phone: company?.phone,
          mobile: company?.mobile,
          manager: company?.manager,
          language: company?.language,
        };
      });
      setData(users);
      setDataSource(users);
    });
  }, [user.access_token]);

  const columns = [
    {
      title: "Naam",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telefoon",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Gsm",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Zaakvoerder",
      dataIndex: "manager",
      key: "manager",
      ellipsis: true,
    },
    {
      title: "Taal",
      dataIndex: "language",
      key: "lanuage",
    },
  ];

  function handleRowAction(record, rowIndex) {
    return {
      onClick: (event) => {
        history.push(`/agent/customers/${record.key}`);
      },
    };
  }

  return (
    <div>
      <h1>Klantenlijst</h1>
      <AutoComplete
        style={{ width: 300 }}
        onSearch={(usernameSearch) => {
          setDataSource(
            data.filter((user) =>
              user.username.toLowerCase().includes(usernameSearch.toLowerCase())
            )
          );
        }}
      >
        <Input.Search size="large" placeholder="Zoek op naam" enterButton />
      </AutoComplete>
      <Table
        columns={columns}
        dataSource={dataSource}
        onRow={handleRowAction}
      />
    </div>
  );
}
