import React from "react";
import { Comment, List } from "antd";
import { useTranslation } from "react-i18next";

export default function CommentList({ comments }) {
  const { t } = useTranslation("common");
  return (
    <List
      dataSource={comments}
      header={`${comments.length} ${
        comments.length > 1
          ? t("commentList.messages")
          : t("commentList.message")
      }`}
      itemLayout="horizontal"
      renderItem={(props) => <Comment {...props} />}
    />
  );
}
