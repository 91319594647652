import moment from "moment";

import {
  Button,
  Modal,
  TimePicker,
  Table,
  Typography,
  Input,
  Radio,
  Space,
} from "antd";
import "./Modals.css";

function LogTimeModal({
  isModalOpen,
  setIsModalOpen,
  handleOk,
  timeLogFormat,
  time,
  setTime,
}) {
  return (
    <Modal
      title="Log uw tijd"
      open={isModalOpen}
      onOk={handleOk}
      okButtonProps={{ disabled: time.isSame(moment("00:00", timeLogFormat)) }}
      onCancel={() => setIsModalOpen(false)}
    >
      <TimePicker
        format={timeLogFormat}
        value={time}
        showNow={false}
        onSelect={(value) =>
          setTime(moment(value.format(timeLogFormat), timeLogFormat))
        }
        onChange={(value) =>
          setTime(
            moment(value ? value.format(timeLogFormat) : "00:00", timeLogFormat)
          )
        }
      />{" "}
      <Button
        shape="round"
        onClick={() => setTime(moment("00:05", timeLogFormat))}
      >
        5m
      </Button>{" "}
      <Button
        shape="round"
        onClick={() => setTime(moment("00:15", timeLogFormat))}
      >
        15m
      </Button>{" "}
      <Button
        shape="round"
        onClick={() => setTime(moment("00:30", timeLogFormat))}
      >
        30m
      </Button>{" "}
      <Button
        shape="round"
        onClick={() => setTime(moment("00:45", timeLogFormat))}
      >
        45m
      </Button>{" "}
      <Button
        shape="round"
        onClick={() => setTime(moment("01:00", timeLogFormat))}
      >
        1h
      </Button>
    </Modal>
  );
}

function TimeLoggedModal({ isModalOpen, setIsModalOpen, dataSource }) {
  const columns = [
    {
      title: "tijd",
      dataIndex: "timeWorked",
      key: "timeWorked",
    },
    {
      title: "agent",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "aangemaakt op",
      dataIndex: "createdAt",
      key: "createdAt",
    },
  ];
  return (
    <Modal
      title="Logs"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
    >
      <Table dataSource={dataSource} columns={columns} />
    </Modal>
  );
}
function InputModal({
  isModalOpen,
  handleOk,
  handleCancel,
  setInput,
  title,
  body,
  okText = "Ok",
  cancelText = "Cancel",
  isTextArea = false,
  defaultValue = "",
}) {
  const sharedProps = {
    onChange: (e) => setInput(e.target.value),
    defaultValue: defaultValue,
  };
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={okText}
      cancelText={cancelText}
    >
      <Typography.Text>{body}</Typography.Text>
      {isTextArea ? (
        <Input.TextArea autoSize {...sharedProps} />
      ) : (
        <Input {...sharedProps} />
      )}
    </Modal>
  );
}

function TypeModal({ isModalOpen, setIsModalOpen, options, handleChange }) {
  return (
    <Modal
      title="Selecteer een type"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
    >
      <Space direction="vertical">
        <Typography.Text>Kies een type voor dit ticket.</Typography.Text>
        <Typography.Text type="secondary">
          Je kan het type achteraf altijd wijzigen in de kolom rechts.
        </Typography.Text>
        <Radio.Group
          options={options}
          onChange={handleChange}
          optionType="button"
          size="large"
        />
      </Space>
    </Modal>
  );
}

export { LogTimeModal, TimeLoggedModal, InputModal, TypeModal };
