import React from "react";

import {
  Button,
  Modal,
  message,
  Typography,
  Space,
  Row,
  Col,
  Descriptions,
  Popconfirm,
} from "antd";

import { formatDateTime } from "../../common/utils/DateHelper";
import { client } from "../../common/utils/ApiClient";

const { Text, Title } = Typography;

export default function CurrentWelcomeMessage({
  user,
  welcomeMessage,
  setWelcomeMessage,
}) {
  React.useEffect(() => {
    client("welcome-message/latest", {
      token: user.access_token,
    }).then((response) => setWelcomeMessage(response));
  }, [user.access_token, setWelcomeMessage]);

  const showCurrentMessage = () => {
    Modal.info({
      title: welcomeMessage.title,
      content: (
        <Typography.Text style={{ whiteSpace: "pre-line" }}>
          {welcomeMessage.content}
        </Typography.Text>
      ),
    });
  };

  const deactivate = (event) => {
    client(`welcome-message/${welcomeMessage.id}/deactivate`, {
      token: user.access_token,
      method: "PUT",
    })
      .then(() => {
        message.success("Het huidige welkomstbericht is gedeactiveerd!");
        setWelcomeMessage(null);
      })
      .catch((response) => {
        Modal.error({
          title: "Oeps!",
          content: "Er is iets mis gegaan, probeer het later nog eens",
        });
      });
  };

  return welcomeMessage ? (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Row>
        <Col span={12} offset={6}>
          <Title level={2}>Huidig welkomstbericht</Title>
          <Descriptions column={1}>
            <Descriptions.Item label="Aangemaakt door">
              {welcomeMessage.user.username}
            </Descriptions.Item>
            <Descriptions.Item label="Aangemaakt op">
              {formatDateTime(welcomeMessage.createdAt)}
            </Descriptions.Item>
            <Descriptions.Item label="Geldig tot en met">
              {welcomeMessage.showUntil
                ? formatDateTime(welcomeMessage.showUntil, "DD/MM/YYYY")
                : "Niet opgegeven"}
            </Descriptions.Item>
          </Descriptions>
          <Space>
            <Button type="primary" onClick={showCurrentMessage}>
              Toon
            </Button>
            <Popconfirm
              title="Ben je zeker dat je dit wilt deactiveren?"
              onConfirm={deactivate}
              okText="Ja"
              cancelText="Nee"
            >
              <Button type="danger">Deactiveer</Button>
            </Popconfirm>
          </Space>
        </Col>
      </Row>
    </div>
  ) : (
    <div
      className="site-layout-background"
      style={{ padding: 24, minHeight: 360 }}
    >
      <Row>
        <Col span={12} offset={6}>
          <Title level={2}>Huidig welkomstbericht</Title>
          <Text strong>Er is momenteel geen welkomstbericht actief!</Text>
        </Col>
      </Row>
    </div>
  );
}
