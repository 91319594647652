import React from "react";

import {
  Typography,
  Card,
  Descriptions,
  Row,
  Col,
  Badge,
  Space,
  Tooltip,
} from "antd";

import { PoweroffOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function CustomerInfo({
  customer,
  updateCompanyInfo,
  updateUserInfo,
}) {
  return (
    <Card title="Klanten details">
      <Row>
        <Col span={8}>
          <Descriptions
            title={
              <Text
                copyable={{
                  text: `Winkelnaam: ${customer.username}\nZaakvoerder: ${customer.company.manager}\nBTW nummer: ${customer.company.vatNumber}`,
                }}
              >
                Algemene informatie
              </Text>
            }
            bordered
            column={1}
          >
            <Descriptions.Item label="Winkelnaam">
              {customer.username}
            </Descriptions.Item>
            <Descriptions.Item label="Zaakvoerder">
              <Text
                editable={{
                  onChange: (text) => updateCompanyInfo(text, "manager"),
                }}
              >
                {customer.company.manager}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="BTW nummer">
              {customer.company.vatNumber}
            </Descriptions.Item>
            <Descriptions.Item label="Taal">
              {customer.company.language}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions
            title={
              <Text
                copyable={{
                  text: `E-mail: ${customer.email}\nTelefoon nummer: ${customer.company.phone}\nGSM nummer: ${customer.company.mobile}`,
                }}
              >
                Communicatie
              </Text>
            }
            bordered
            column={1}
          >
            <Descriptions.Item label="E-mail">
              {customer.email}
            </Descriptions.Item>
            <Descriptions.Item label="Telefoon nummer">
              <Text
                editable={{
                  onChange: (text) => updateCompanyInfo(text, "phone"),
                }}
              >
                {customer.company.phone}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="GSM nummer">
              <Text
                editable={{
                  onChange: (text) => updateCompanyInfo(text, "mobile"),
                }}
              >
                {customer.company.mobile}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="E-mail notificaties">
              <Space>
                <Badge
                  status={customer.emailNotifications ? "success" : "error"}
                  text={
                    customer.emailNotifications ? (
                      <Text>Aan</Text>
                    ) : (
                      <Text>Uit</Text>
                    )
                  }
                />
                <Tooltip
                  title={customer.emailNotifications ? "Zet uit" : "Zet aan"}
                >
                  <PoweroffOutlined
                    onClick={() =>
                      updateUserInfo(
                        !customer.emailNotifications,
                        "emailNotifications"
                      )
                    }
                    style={{ color: "#1890ff" }}
                  />
                </Tooltip>
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions
            title={
              <Text
                copyable={{
                  text: `${customer.company.streetWithNumber},\n${customer.company.zipCode} ${customer.company.city}`,
                }}
              >
                Adres
              </Text>
            }
            bordered
            column={1}
          >
            <Descriptions.Item label="Straat">
              <Text
                editable={{
                  onChange: (text) =>
                    updateCompanyInfo(text, "streetWithNumber"),
                }}
              >
                {customer.company.streetWithNumber}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Postcode">
              <Text
                editable={{
                  onChange: (text) => updateCompanyInfo(text, "zipCode"),
                }}
              >
                {customer.company.zipCode}
              </Text>
            </Descriptions.Item>
            <Descriptions.Item label="Stad">
              <Text
                editable={{
                  onChange: (text) => updateCompanyInfo(text, "city"),
                }}
              >
                {customer.company.city}
              </Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Card>
  );
}
