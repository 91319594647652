import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Table, Tabs, Layout, message, Dropdown, Button } from "antd";
import { useHistory } from "react-router-dom";

import "./Overview.css";
import CustomHeader from "../Header";
import { client } from "../../common/utils/ApiClient";
import connect from "../../common/utils/SocketClient";
import { formatDateTime } from "../../common/utils/DateHelper";

const { Content } = Layout;

function dataMapper({ id, subject, createdAt, lastMessage, isUnread }) {
  return {
    key: id,
    id: id,
    subject,
    createdAt: createdAt,
    updatedAt: lastMessage ? lastMessage : createdAt,
    isUnread,
  };
}

export default function Overview({ user }) {
  let history = useHistory();
  const [dataSourceActive, setDataSourceActive] = React.useState([]);
  const [dataSourceArchive, setDataSourceArchive] = React.useState([]);
  const { t } = useTranslation("user");

  React.useEffect(() => {
    client(`tickets/me`, {
      token: user.access_token,
    })
      .then((response) => {
        const mappedDataActive = response
          .filter((data) => data.status.toLowerCase() !== "done")
          .map((data) => dataMapper(data));
        setDataSourceActive(mappedDataActive);
        const mappedDataArchived = response
          .filter((data) => data.status.toLowerCase() === "done")
          .map((data) => dataMapper(data));
        setDataSourceArchive(mappedDataArchived);
      })
      .catch(() =>
        message.error(t("resultError"))
      );
  }, [user.access_token, t]);

  React.useEffect(() => {
    const socket = connect(user.access_token);
    socket.on(`ticket-updates-overview`, (data) => {
      if (data.userId !== user.id) {
        return;
      }
      setDataSourceActive((dataSource) => {
        const index = dataSource.findIndex((el) => el.id === data.id);
        if (index === -1 && data.status.toLowerCase() !== "done") {
          // add ticket
          let newDataSource = dataSource.slice();
          newDataSource.push(dataMapper(data));
          return newDataSource;
        } else if (index >= 0 && data.status.toLowerCase() === "done") {
          // remove ticket (was active, now archived)
          return dataSource.filter((el) => el.id !== data.id);
        } else {
          //update
          let newDataSource = [...dataSource];
          newDataSource[index] = dataMapper(data);
          return newDataSource;
        }
      }, []);

      setDataSourceArchive((dataSource) => {
        const index = dataSource.findIndex((el) => el.id === data.id);
        if (index === -1 && data.status.toLowerCase() === "done") {
          // add ticket
          let newDataSource = dataSource.slice();
          newDataSource.push(dataMapper(data));
          return newDataSource;
        } else if (index && data.status.toLowerCase() !== "done") {
          // remove ticket (was active, now archived)
          return dataSource.filter((el) => el.id !== data.id);
        } else {
          //update
          let newDataSource = [...dataSource];
          newDataSource[index] = dataMapper(data);
          return newDataSource;
        }
      });
    });
    return () => socket.disconnect();
  }, [user]);

  function handleRowAction(record, rowIndex) {
    return {
      onClick: (event) => {
        history.push(`/user/overview/${record.key}`);
      },
    };
  }

  const columns = [
    {
      title: t("overview.id"),
      dataIndex: "id",
      key: "id",
      width: "75px",
    },
    {
      title: t("overview.subject"),
      dataIndex: "subject",
      key: "subject",
      defaultSortOrder: "descend",
      ellipsis: true,
    },
    {
      title: t("overview.created"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => formatDateTime(data),
    },
    {
      title: t("overview.lastUpdated"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      showSorterTooltip: false,
      sortOrder: "descend",
      sortDirections: ["descend"],
      render: (data) => formatDateTime(data),
    },
  ];

  const handleLoadMore = () => {
    const skip = dataSourceArchive.length + dataSourceActive.length
    client(`tickets/me?skip=${skip}`, {
      token: user.access_token,
    }).then((response) => {
      if(!response.length){
        message.info(t("overview.loadMoreTickets.endMessage"))
        return
      }
      const mappedDataActive = response
        .filter((data) => data.status.toLowerCase() !== "done")
        .map((data) => dataMapper(data));
      setDataSourceActive(prevActiveData => [...prevActiveData, ...mappedDataActive]);
      const mappedDataArchived = response
        .filter((data) => data.status.toLowerCase() === "done")
        .map((data) => dataMapper(data));
      setDataSourceArchive(prevArchiveData => [...prevArchiveData, ...mappedDataArchived]);
    })
    .catch(() =>
      message.error(t("resultError"))
    );
  }

  const items = [
    {
      label: (<Button type="text" onClick={handleLoadMore}>{t("overview.loadMoreTickets.label")}</Button>),
      key: 'loadNextTickets',
    },
    // Add more menu items as needed
  ];

  return (
    <>
      <CustomHeader selectedKeys={["overview"]} />
      <Content style={{ padding: "0 200px" }}>
        <div>
          <Tabs defaultActiveKey="1"
          items={[
            {label: t("overview.tab.active"),
            key: "1",
            children: (
              <Table
                dataSource={dataSourceActive}
                columns={columns}
                onRow={handleRowAction}
                rowClassName={(record) => (record.isUnread ? "unread" : "")}
              />)}
            ,
            {
              label: (<Dropdown menu={{items}} trigger={['contextMenu']}>
              <div>{t("overview.tab.archive")}</div>
              </Dropdown>),
              key: "2",
              children: (
                <Table
                  dataSource={dataSourceArchive}
                  columns={columns}
                  onRow={handleRowAction}
                  />)}]} />
                  
        </div>
      </Content>
    </>
  );
}
