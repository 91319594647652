import React from "react";
import moment from "moment";
import "moment/locale/nl";
import { DualAxesChart, DualAxesChartProps } from "@opd/g2plot-react";

import { client } from "../../../common/utils/ApiClient";

export default function MedianLeadTimeGraph({ year }) {
  const [graphData, setGraphData] = React.useState([]);
  const [lineData, setLineData] = React.useState([]);
  const chartRef = React.useRef();

  React.useEffect(() => {
    if (!year) {
      return;
    }
    client(`statistics/year/median-lead-time?year=${year}`)
      .then((response) => {
        setGraphData(response);
      })
      .catch(() => setGraphData([]));

    client(`statistics/year/median-lead-time-total?year=${year}`).then(
      (response) => {
        setLineData(response);
      }
    );
  }, [year]);

  const parseDurationLong = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    const time = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    return `${Math.floor(duration.asDays())}d ${time}`;
  };

  const parseDurationDays = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    return `${Math.floor(duration.asDays())}d`;
  };

  const config: DualAxesChartProps = {
    data: [graphData, lineData],
    xField: "month",
    yField: ["value", "value"],
    xAxis: {
      label: {
        formatter: (text) => moment(text).locale("nl").format("MMMM"),
      },
    },
    yAxis: {
      value: {
        label: {
          formatter: (text) => parseDurationDays(text),
        },
      },
    },
    meta: { value: { alias: "Alle tickets" } },
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField: "type",
        tooltip: {
          formatter: (object) => {
            return {
              name: object.type,
              value: parseDurationLong(object.value),
            };
          },
        },
        color: ({ type }) => {
          if (type === "Software") {
            return "#61DDAA";
          } else if (type === "Hardware") {
            return "#5B8FF9";
          } else if (type === "LSNAV") {
            return "#F6BD16";
          } else if (type === "O365") {
            return "#6F5EF9";
          }
          return "#65789B";
        },
      },
      {
        geometry: "line",
        label: {
          formatter: (object) => parseDurationLong(object.value),
          style: {
            fill: "rgba(255,255,255,0.85)",
          },
        },
        tooltip: {
          formatter: (object) => {
            return {
              name: "Alle tickets",
              value: parseDurationLong(object.value),
            };
          },
        },
        lineStyle: {
          stroke: "#F08BB4",
        },
        point: {
          size: 5,
          shape: "diamond",
          style: {
            fill: "white",
            stroke: "#F08BB4",
            lineWidth: 2,
          },
        },
      },
    ],
  };

  return (
    <>
      <DualAxesChart {...config} chartRef={chartRef} />
    </>
  );
}
