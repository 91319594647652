import React from "react";

import { Card, Typography, Space, DatePicker } from "antd";
import moment from "moment";
import YearOverviewCard from "./cards/YearOverviewCard";

export default function Year() {
  const [year, setYear] = React.useState(moment().format("YYYY"));

  const changeYear = (value) => {
    if (!value) {
      return;
    }
    setYear(value.format("YYYY"));
  };
  return (
    <>
      <Typography.Title level={2}>Statistieken voor een jaar</Typography.Title>
      <Card>
        <Space>
          <Typography.Text>Kies een jaar: </Typography.Text>
          <DatePicker
            defaultValue={moment()}
            onChange={changeYear}
            picker="year"
          />
        </Space>
      </Card>
      <YearOverviewCard year={year} />
    </>
  );
}
