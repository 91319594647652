import React from "react";
import { Col, Form, Row, Button, Select, Input, Tooltip, Checkbox } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { client } from "../../common/utils/ApiClient";
import { useLocalStorageState } from "../../common/utils/CustomHooks";
import "./SearchFilters";

export default function SearchFilters({ user, setQueryString }) {
  const [filterUsers, setFilterUsers] = React.useState([]);
  const [filterAgents, setFilterAgents] = React.useState([]);
  const [filterType, setFilterType] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState([]);
  const [filters, setFilters] = useLocalStorageState("advancedSearchFilters");

  const [form] = Form.useForm();

  React.useEffect(() => {
    client("tickets/status", { token: user.access_token }).then((response) => {
      const status = response.map((el) => {
        return { text: el, value: el };
      });
      setFilterStatus(status);
    });
    client("tickets/types", { token: user.access_token }).then((response) => {
      const types = response.map((el) => {
        return { text: el, value: el };
      });
      setFilterType(types);
    });
    client("users/users-simple", { token: user.access_token }).then(
      (response) => {
        const users = response.map((user) => {
          return { label: user.username, value: user.id };
        });
        setFilterUsers(users);
      }
    );
    client("users/agents", { token: user.access_token }).then((response) => {
      const agents = response.map((agent) => {
        return { label: agent.username, value: agent.id };
      });
      setFilterAgents(agents);
    });
  }, [user.access_token]);

  React.useEffect(() => {
    if (filters) {
      form.setFieldsValue(filters);
    }
  }, [form, filters]);

  const onFinish = (values) => {
    for (var propName in values) {
      if (values[propName] === null || values[propName] === undefined) {
        delete values[propName];
      }
    }
    setQueryString(new URLSearchParams(values).toString());
    if (values.remember) {
      setFilters(values);
    } else {
      setFilters(null);
    }
  };

  const onLimitChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      form.setFieldsValue({ limit: value });
    }
  };

  const onRememberChange = (e) => {
    if (e.target.checked) {
      setFilters(form.getFieldValue());
    } else {
      setFilters(null);
    }
  };

  return (
    <>
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
        initialValues={{ limit: "50" }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Winkel" name="userId">
              <Select
                showSearch
                placeholder="Kies een winkel"
                optionFilterProp="label"
                options={filterUsers}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label="Status">
              <Select
                showSearch
                placeholder="Kies een status"
                options={filterStatus}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="type" label="Type">
              <Select
                showSearch
                placeholder="Kies een type"
                options={filterType}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Agent" name="agentId">
              <Select
                showSearch
                placeholder="Kies een agent"
                optionFilterProp="label"
                options={filterAgents}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Tekst" name="text">
              <Input
                placeholder="Zoeken naar tekst"
                allowClear
                suffix={
                  <Tooltip title="Er wordt enkel gezocht in de titel en in het origineel ticket, niet in de comments">
                    <InfoCircleOutlined
                      style={{ color: "rgba(255,255,255,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Limiet" name="limit">
              <Input
                onChange={onLimitChange}
                allowClear
                suffix={
                  <Tooltip title="Het aantal tickets dat opgehaald wordt. Leeg laten voor alle tickets, LET OP dit kan lang duren!">
                    <InfoCircleOutlined
                      style={{ color: "rgba(255,255,255,.45)" }}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Zoeken
            </Button>
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => {
                form.resetFields();
                setFilters(null);
                setQueryString("");
              }}
            >
              Reset
            </Button>
            <Form.Item
              name="remember"
              valuePropName="checked"
              style={{ display: "inline-block" }}
            >
              <Checkbox onChange={onRememberChange}>
                Onthoud zoek filters
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}
