import React from "react";

import { useHistory } from "react-router-dom";
import { Table, Tag } from "antd";

export default function SearchResults({ dataSource }) {
  let history = useHistory();
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "75px",
    },
    {
      title: "Onderwerp",
      dataIndex: "subject",
      key: "subject",
      ellipsis: true,
    },
    {
      title: "Klant",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Aanmaak datum",
      dataIndex: "creationTimestamp",
      key: "creationTimestamp",
      ellipsis: true,
    },
    {
      title: "Laatste bericht",
      dataIndex: "updatedTimestamp",
      key: "updatedTimestamp",
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        status = status.toUpperCase();
        let color = "";
        switch (status) {
          case "TO DO":
            color = "magenta";
            break;
          case "IN PROGRESS":
            color = "volcano";
            break;
          case "WAITING ON AVEVE":
            color = "lime";
            break;
          case "WAITING ON CUSTOMER":
            color = "gold";
            break;
          case "WAITING ON SUPPLIER":
            color = "orange";
            break;
          default:
            color = "green";
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
      ellipsis: true,
    },
    {
      title: "Verwerker",
      dataIndex: "agent",
      key: "agent",
      ellipsis: true,
    },
  ];

  function handleRowAction(record, rowIndex) {
    return {
      onClick: (event) => {
        history.push(`/agent/overview/${record.key}`);
      },
    };
  }

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      onRow={handleRowAction}
      rowClassName={(record) => {
        const unread = record.isUnreadAgent ? "unread" : "";
        const toDo = record.status.toUpperCase() === "TO DO" ? "toDo" : "";
        return unread ? unread : toDo;
      }}
    />
  );
}
