import React from "react";

import {
  Button,
  Cascader,
  Descriptions,
  Drawer,
  message,
  Form,
  Input,
  Tooltip,
  List,
  Comment,
  Typography,
  Divider,
  Space,
  Alert,
  Upload,
} from "antd";

import { client } from "../../common/utils/ApiClient";
import { formatDateTime } from "../../common/utils/DateHelper";

const { Paragraph } = Typography;

function CreateIncident({
  onClose,
  open,
  onFinish,
  ticketId,
  user,
  isArchived,
}) {
  const [categories, setCategories] = React.useState();
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!open) {
      return;
    }
    client(`tickets/${ticketId}/comments`, { token: user.access_token })
      .then((response) => {
        form.setFieldsValue({
          contentBody: response,
        });
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );

    client(`attachments/${ticketId}`, { token: user.access_token })
      .then((response) => {
        form.setFieldsValue({
          files: response.map((item) => ({
            id: item.id,
            uid: item.id,
            name: item.fileName,
          })),
        });
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token, open, ticketId, form]);

  React.useEffect(() => {
    client(`ivanti-categories`, { token: user.access_token })
      .then((response) => {
        // generate structure like ant design expects
        const result = response.reduce(function (r, a) {
          r[a.category] = r[a.category] || [];
          const child = {
            value: a.subCategory ? a.subCategory : undefined,
            label: a.subCategory ? a.subCategory : "Andere",
          };
          r[a.category].push(child);
          return r;
        }, Object.create(null));
        let categories = [];
        for (const property in result) {
          categories.push({
            value: property,
            label: property,
            children: result[property],
          });
        }
        setCategories(categories);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token]);

  // Not completly sure why, but we need this
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (_, files) => {
    const fileList = form.getFieldValue("files");
    form.setFieldsValue({
      files: [...fileList, ...files],
    });
    return false;
  };

  const onRemove = (file) => {
    const fileList = form.getFieldValue("files");
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    form.setFieldsValue({
      files: newFileList,
    });
  };

  return (
    <Drawer
      title="Creeër een nieuw Ivanti incident"
      placement="right"
      mask={false}
      onClose={onClose}
      open={open}
      width={480}
    >
      <Form onFinish={onFinish} form={form}>
        <Form.Item
          label="Categorie"
          name="category"
          rules={[
            {
              required: true,
              message: "Geef een categorie op!",
            },
          ]}
        >
          <Cascader
            options={categories}
            placeholder="Kies een category"
            disabled={isArchived}
          />
        </Form.Item>
        <Form.Item name="contentBody" required>
          <Input.TextArea rows={20} disabled={isArchived} />
        </Form.Item>
        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="upload"
        >
          <Upload
            name="file"
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            multiple={true}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isArchived}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

function Incident({
  onClose,
  open,
  incident,
  onJournalNote,
  setMessageCount,
  setResolved,
  user,
}) {
  const [history, setHistory] = React.useState();
  const [isResolved, setIsResolved] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  React.useEffect(() => {
    if (!incident) {
      return null;
    }
    const history = incident.journalNotes;
    if (Array.isArray(history) && !history.length) {
      history.push({
        contentBody: incident.contentBody,
        createdAt: incident.createdAt,
      });
    }
    setHistory(history);
    setMessageCount(history.length);
    const isResolved =
      incident.status.toLowerCase() === "resolved" ||
      incident.status.toLowerCase() === "closed";
    setResolved(isResolved);
    setIsResolved(isResolved);
  }, [incident, setMessageCount, setResolved]);

  React.useEffect(() => {
    if (!incident) {
      return null;
    }
    client(`incident-attachments/incident/${incident.id}`, {
      token: user.access_token,
    })
      .then((response) => {
        const files = response.map((item) => ({
          id: item.id,
          uid: item.id,
          name: item.attachment.fileName,
        }));
        setFiles(files);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token, incident]);

  if (!incident) {
    return null;
  }

  return (
    <Drawer
      title="Ivanti incident"
      placement="right"
      mask={false}
      onClose={onClose}
      open={open}
      width={480}
    >
      <Alert
        style={{
          display: isResolved ? null : "none",
        }}
        message={
          <>
            <Typography.Title level={5}>{incident.status}</Typography.Title>
            <Typography.Paragraph>{incident.resolution}</Typography.Paragraph>
          </>
        }
        type="success"
      />
      <Descriptions column={1} size="small" title={`Incident ${incident.id}`}>
        <Descriptions.Item label="Incident nummer">
          <Tooltip title="Het incident nummer in Ivanti">
            {incident.incidentNumber ? incident.incidentNumber : "onbekend"}
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Rec id">
          <Tooltip title="Het rec id in Ivanti">
            {incident.recId ? incident.recId : "onbekend"}
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="SAP nr">
          <Tooltip title="Het SAP nr van de klant, ook wel profile link in Ivanti">
            {incident.profileLink}
          </Tooltip>
        </Descriptions.Item>
        <Descriptions.Item label="Service">
          {incident.service}
        </Descriptions.Item>
        <Descriptions.Item label="Category">
          {incident.category}
        </Descriptions.Item>
        <Descriptions.Item label="Subcategory">
          {incident.subCategory ? incident.subCategory : "Andere"}
        </Descriptions.Item>
        <Descriptions.Item label="Status">{incident.status}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Alert
        style={{
          display:
            (!incident.incidentNumber || !incident.recId) && !isResolved
              ? null
              : "none",
        }}
        message="Er kunnen geen nieuwe berichten worden aangemaakt zolang er geen incident nummer en rec id is van ivanti. Probeer het later nog eens."
        type="warning"
      />
      <Button
        disabled={!incident.incidentNumber || !incident.recId ? true : false}
        style={{
          display: !isResolved ? null : "none",
        }}
        onClick={onJournalNote}
        type="primary"
      >
        Nieuw bericht
      </Button>
      <List
        pagination
        className="comment-list"
        header={`Geschiedenis: ${history ? history.length : 0} antwoord(en)`}
        itemLayout="horizontal"
        dataSource={history}
        renderItem={(item) => (
          <li>
            <Comment
              content=<Paragraph
                ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
              >
                {item.contentBody}
              </Paragraph>
              datetime={formatDateTime(item.createdAt)}
              author={item.subject === null ? "MyService" : "Ivanti"}
              style={{
                backgroundColor: item.subject === null ? null : "#613400",
              }}
            />
          </li>
        )}
      />
      <Typography.Title level={5}>Attachments in ivanti</Typography.Title>
      <Upload name="file" defaultFileList={files} />
    </Drawer>
  );
}

function CreateJournalNote({ onCancel, open, onFinish, ticketId, user }) {
  const [form] = Form.useForm();

  React.useEffect(() => {
    if (!open) {
      return;
    }
    client(`tickets/${ticketId}/comments`, { token: user.access_token })
      .then((response) => {
        form.setFieldsValue({
          contentBody: response,
        });
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );

    client(`attachments/${ticketId}`, { token: user.access_token })
      .then((response) => {
        form.setFieldsValue({
          files: response.map((item) => ({
            id: item.id,
            uid: item.id,
            name: item.fileName,
          })),
        });
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token, open, ticketId, form]);

  // Not completly sure why, but we need this
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (_, files) => {
    const fileList = form.getFieldValue("files");
    form.setFieldsValue({
      files: [...fileList, ...files],
    });
    return false;
  };

  const onRemove = (file) => {
    const fileList = form.getFieldValue("files");
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    form.setFieldsValue({
      files: newFileList,
    });
  };

  return (
    <Drawer
      title="Maak een nieuwe reactie"
      placement="right"
      mask={false}
      onClose={onCancel}
      open={open}
      width={480}
    >
      <Form onFinish={onFinish} form={form}>
        <Form.Item name="contentBody" required>
          <Input.TextArea rows={20} />
        </Form.Item>
        <Form.Item
          name="files"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="upload"
        >
          <Upload
            name="file"
            beforeUpload={beforeUpload}
            onRemove={onRemove}
            multiple={true}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button onClick={() => form.resetFields(["contentBody"])}>
              Reset tekst
            </Button>
            <Button onClick={() => form.resetFields()}>Reset alles</Button>
            <Button onClick={onCancel}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

export default function IncidentDrawer({
  user,
  ticketId,
  open,
  setOpen,
  setResolved,
  setMessageCount,
  isArchived,
  handleSend,
}) {
  const [incident, setIncident] = React.useState(null);
  const [incidentCreateOpen, setIncidentCreateOpen] =
    React.useState(false);
  const [incidentOpen, setIncidentOpen] = React.useState(false);
  const [journalNoteOpen, setJournalNoteOpen] = React.useState(false);

  React.useEffect(() => {
    client(`incidents/ticket/${ticketId}`, { token: user.access_token })
      .then((response) => {
        setIncident(response);
      })
      .catch((response) => {
        if (response.status === 404) {
          return;
        }
        message.error("Er is iets misgelopen met het updaten van de data.");
      });
  }, [user.access_token, ticketId, incidentCreateOpen, journalNoteOpen]);

  React.useEffect(() => {
    if (!open) {
      return;
    }
    if (!incident) {
      setIncidentCreateOpen(true);
    } else {
      setIncidentOpen(true);
    }
  }, [open, incident]);

  const onCreateIncident = ({ category, contentBody, files }) => {
    const data = {
      ticketId,
      contentBody,
      category: category[0],
      subCategory: category[1],
    };
    client("incidents", { token: user.access_token, data })
      .then((response) => {
        createIncidentAttachments(response.id, files);
        setIncidentCreateOpen(false);
        setIncidentOpen(true);
        if(handleSend){
          handleSend();
        }
      })
      .catch(() =>
        message.error(
          "Er is iets misgelopen met het versturen van een incident."
        )
      );
  };

  const createIncidentAttachments = (incidentId, files) => {
    if (files === undefined || (Array.isArray(files) && !files.length)) {
      return;
    }
    const data = files.map((item) => ({
      incidentId,
      attachmentId: item.id,
    }));
    client("incident-attachments", { token: user.access_token, data }).catch(
      () =>
        message.error(
          "Er is iets misgelopen met het versturen van de bijlages."
        )
    );
  };

  const onClose = () => {
    if (incidentOpen && !isArchived) {
      const data = { hasIvantiResponse: false };
      client(`tickets/${ticketId}`, {
        data,
        token: user.access_token,
        method: "PUT",
      });
    }
    setOpen(false);
    setIncidentCreateOpen(false);
    setIncidentOpen(false);
  };

  const onJournalNote = () => {
    setJournalNoteOpen(true);
  };

  const onCreateJournalNote = ({ contentBody, files }) => {
    const data = {
      incidentId: incident.id,
      contentBody,
    };
    client("journal-notes", { token: user.access_token, data })
      .then(() => {
        createIncidentAttachments(incident.id, files);
        setJournalNoteOpen(false);
        setIncidentOpen(true);
      })
      .catch((response) => {
        message.error("Er is iets misgelopen met het opslaan van de data.");
      });
  };

  const onCancel = () => {
    setJournalNoteOpen(false);
  };

  return (
    <>
      <CreateIncident
        open={incidentCreateOpen}
        onClose={onClose}
        onFinish={onCreateIncident}
        ticketId={ticketId}
        user={user}
        isArchived={isArchived}
      />
      <Incident
        open={incidentOpen}
        onClose={onClose}
        incident={incident}
        onJournalNote={onJournalNote}
        setMessageCount={setMessageCount}
        setResolved={setResolved}
        user={user}
      />
      <CreateJournalNote
        open={journalNoteOpen}
        onCancel={onCancel}
        onFinish={onCreateJournalNote}
        user={user}
        ticketId={ticketId}
      />
    </>
  );
}
