import React from "react";
import moment from "moment";
import { Row, Col, Card, DatePicker, Typography, Space } from "antd";
import { FileTextOutlined, ClockCircleOutlined } from "@ant-design/icons";

import InfoCard from "./cards/InfoCard";
import GraphCard from "./cards/GraphCard";

export default function Period({ user }) {
  const [range, setRange] = React.useState([
    moment().startOf("isoWeek"),
    moment().endOf("isoWeek"),
  ]);

  const parseDurationHour = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    const time = moment.utc(duration.asMilliseconds()).format(":mm");
    return `${Math.floor(duration.asHours())}${time}`;
  };

  const parseDuration = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
  };

  const parseDurationLong = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    const time = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    return `${Math.floor(duration.asDays())}d ${time}`;
  };

  return (
    <>
      <Typography.Title level={2}>
        Statistieken voor een periode
      </Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Space>
              <Typography.Text>Kies een periode: </Typography.Text>
              <DatePicker.RangePicker
                ranges={{
                  Vandaag: [moment(), moment()],
                  "Deze week": [
                    moment().startOf("isoWeek"),
                    moment().endOf("isoWeek"),
                  ],
                  "Deze maand": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Vorige maand": [
                    moment().subtract(1, "months").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                }}
                defaultValue={range}
                onChange={(dates) => setRange(dates)}
                allowClear={false}
              />
            </Space>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <InfoCard
            user={user}
            range={range}
            title={"Totaal aantal ticketten"}
            icon={<FileTextOutlined />}
            endpoint={"statistics/tickets"}
          />
        </Col>
        <Col span={6}>
          <InfoCard
            user={user}
            range={range}
            title={"Totaal aantal uren gewerkt"}
            icon={<ClockCircleOutlined />}
            endpoint={"statistics/time-log"}
            parser={parseDurationHour}
          />
        </Col>
        <Col span={6}>
          <InfoCard
            user={user}
            range={range}
            title={"Mediaan eerste reactie"}
            icon={<ClockCircleOutlined />}
            endpoint={"statistics/tickets/first-reaction"}
            totalEndpoint={"statistics/tickets/first-reaction/total"}
            parser={parseDuration}
          />
        </Col>
        <Col span={6}>
          <InfoCard
            user={user}
            range={range}
            title={"Mediaan doorlooptijd"}
            icon={<ClockCircleOutlined />}
            endpoint={"statistics/tickets/lead-time"}
            totalEndpoint={"statistics/tickets/lead-time/total"}
            parser={parseDurationLong}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <GraphCard user={user} range={range} />
        </Col>
      </Row>
    </>
  );
}
