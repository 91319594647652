import React from "react";

import { Button, Card, List, Collapse, Typography, message } from "antd";
import { ExclamationCircleTwoTone, FormOutlined } from "@ant-design/icons";
import { client } from "../../common/utils/ApiClient";
import ProductInformationDrawer from "./ProductInfoDrawer";
import { InputModal } from "./Modals";

const { Panel } = Collapse;
const { Text } = Typography;

export default function LeftInformationCard({ ticketUser, user }) {
  const [companyInfo, setCompanyInfo] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const [isVisibleEditCompanyNote, setIsVisibleEditCompanyNote] =
    React.useState(false);
  const [companyNote, setCompanyNote] = React.useState(null);
  const [inputText, setInputText] = React.useState(null);

  React.useEffect(() => {
    client(`users/${ticketUser.id}/company`, {
      token: user.access_token,
    }).then((responseData) => {
      setCompanyInfo(responseData);
      setCompanyNote(responseData.note?.content);
    });
    setIsLoading(false);
  }, [ticketUser, user.access_token]);

  const genHeader = () =>
    companyNote ? (
      <Text>
        <ExclamationCircleTwoTone twoToneColor="#52c41a" /> Er is een notitie
      </Text>
    ) : (
      <Text>Geen notitie</Text>
    );

  const genUpdateNote = () => (
    <FormOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
        setIsVisibleEditCompanyNote(true);
      }}
    />
  );

  const handleCancel = () => {
    setIsVisibleEditCompanyNote(false);
  };

  const updateCompanyNote = () => {
    const data = {
      content: inputText,
      company_id: companyInfo.id,
    };
    client(`company-notes`, { data, token: user.access_token })
      .then((responseData) => message.success("De notitie is opgeslagen!"))
      .catch(() =>
        message.error("Er is iets misgelopen met het opslaan van de notitie!")
      );
    setCompanyNote(inputText);
    setIsVisibleEditCompanyNote(false);
  };

  return isLoading ? null : (
    <>
      <Card title="Klanten informatie">
        <List>
          <List.Item>
            <Collapse style={{ width: "100vw" }}>
              <Panel header={genHeader()} key="1" extra={genUpdateNote()}>
                <p>{companyNote}</p>
              </Panel>
            </Collapse>
          </List.Item>
          <List.Item>
            <b>Naam:</b>
            <br /> {ticketUser.username}
            <br />
            <b>Manager: </b> <br />
            {companyInfo.manager}
          </List.Item>
          <List.Item>
            <b>Telefoon nummer: </b>
            <br />
            {companyInfo.phone} <br />
            <b>GSM nummer: </b>
            <br />
            {companyInfo.mobile}
            <br />
            <b>E-mailadres:</b>
            <br />
            {ticketUser.email}
          </List.Item>
          <List.Item>
            <b>Adres:</b>
            <br /> {companyInfo.streetWithNumber}, {companyInfo.zipCode}{" "}
            {companyInfo.city} <br />
            <b>Language:</b> <br />
            {companyInfo.language}
          </List.Item>
          <List.Item>
            <b>BTW Nummer:</b>
            <br /> {companyInfo.vatNumber}
          </List.Item>
        </List>
        <Button onClick={() => setVisible(true)}>Product informatie</Button>
      </Card>
      <ProductInformationDrawer
        visible={visible}
        setVisible={setVisible}
        user={user}
        ticketUser={ticketUser}
      />
      <InputModal
        isVisible={isVisibleEditCompanyNote}
        handleOk={updateCompanyNote}
        handleCancel={handleCancel}
        setInput={setInputText}
        title="Nieuwe notitie aanmaken"
        body="Geef hier uw opmerking in:"
        okText="Opslaan"
        cancelText="Annuleren"
        isTextArea={true}
        defaultValue={companyNote}
      />
    </>
  );
}
