import React from "react";

import { Row, Col, Button } from "antd";

import { client } from "../../common/utils/ApiClient";

export default function SearchCustom({ user, setDataSource, dataMapper }) {
  const searchOpenTicketsResolvedIvanti = () => {
    client("tickets/ivanti-resolved", { token: user.access_token }).then(
      (response) => setDataSource(dataMapper(response))
    );
  };

  const searchOpenTicketsWithEmptyType = () => {
    client("tickets/type-empty", { token: user.access_token }).then(
      (response) => setDataSource(dataMapper(response))
    );
  };

  return (
    <Row>
      <Col>
        <Button shape="round" onClick={searchOpenTicketsResolvedIvanti}>
          Zoek op open tickets met een opgeloste Ivanti status
        </Button>
      </Col>
      <Col>
        <Button shape="round" onClick={searchOpenTicketsWithEmptyType}>
          Zoek op open tickets met een leeg type
        </Button>
      </Col>
    </Row>
  );
}
