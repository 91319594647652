import React from "react";

import moment from "moment";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Typography,
  Space,
  List,
  Statistic,
} from "antd";

import { client } from "../../common/utils/ApiClient";
import { formatDateTime } from "../../common/utils/DateHelper";

export default function Timesheets({ user }) {
  const [data, setData] = React.useState([]);
  const [range, setRange] = React.useState([
    moment().startOf("month"),
    moment().endOf("month"),
  ]);

  React.useEffect(() => {
    if (!range) {
      return;
    }
    client(
      `time-log/timesheets?start=${formatDateTime(
        range[0],
        "YYYY-MM-DD"
      )}&end=${formatDateTime(range[1], "YYYY-MM-DD")}`,
      {
        token: user.access_token,
      }
    )
      .then((response) => setData(response))
      .catch(() => setData([]));
  }, [user.access_token, range]);

  const parseDurationHour = (timedelta) => {
    const duration = moment.duration(timedelta, "seconds");
    const time = moment.utc(duration.asMilliseconds()).format(":mm");
    return `${Math.floor(duration.asHours())}${time}`;
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Space>
              <Typography.Text>Kies een periode: </Typography.Text>
              <DatePicker.RangePicker
                ranges={{
                  Vandaag: [moment(), moment()],
                  "Deze week": [
                    moment().startOf("isoWeek"),
                    moment().endOf("isoWeek"),
                  ],
                  "Deze maand": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Vorige maand": [
                    moment().subtract(1, "months").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                }}
                defaultValue={range}
                onChange={(dates) => setRange(dates)}
                allowClear={false}
              />
            </Space>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <List
            grid={{ gutter: 16, column: 6 }}
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <Card>
                  <Statistic
                    title={item.username}
                    value={parseDurationHour(item.totalTime)}
                  />
                </Card>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
