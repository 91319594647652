import { io } from "socket.io-client";
const apiURL = process.env.REACT_APP_API_URL;

function connect(access_token) {
  return io(apiURL, {
    path: "/ws/socket.io",
    extraHeaders: { Authorization: `bearer ${access_token}` },
  });
}

export default connect;
