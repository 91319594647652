import React from "react";
import {
  Col,
  Form,
  Button,
  Select,
  Input,
  Space,
  Typography,
  Switch,
  Row,
  Radio,
} from "antd";

import { client } from "../../common/utils/ApiClient";

const { Text } = Typography;

export default function Filters({ user, query, search, priorities }) {
  const [filterUsers, setFilterUsers] = React.useState([]);
  const [filterStatus, setFilterStatus] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [prioritiesRadio, setPrioritiesRadio] = React.useState([]);
  const [priority, setPriority] = React.useState(0);

  const [form] = Form.useForm();

  React.useEffect(() => {
    client("tickets/status", { token: user.access_token }).then((response) => {
      const status = response.map((el) => {
        return { text: el, value: el };
      });
      setFilterStatus(status);
    });
    client("users/users-simple", { token: user.access_token }).then(
      (response) => {
        const users = response.map((user) => {
          return { label: user.username, value: user.id };
        });
        setFilterUsers(users);
      }
    );
  }, [user.access_token]);

  React.useEffect(() => {
    var values = [{ label: "geen", value: 0 }];
    for (var key in priorities) {
      values.push({ label: priorities[key], value: key });
    }
    setPrioritiesRadio(values);
  }, [priorities]);

  const onFinish = () => {
    let values = getFilterValues();
    search({ ...query, ...values });
  };

  const getFilterValues = () => {
    let values = form.getFieldsValue(true);
    for (var propName in values) {
      if (
        values[propName] === null ||
        values[propName] === undefined ||
        values[propName] === ""
      ) {
        delete values[propName];
      }
    }
    return values;
  };

  const assignedToMe = (checked) => {
    let values = getFilterValues();
    let newQuery = { ...query, ...values };
    if (checked) {
      newQuery["agentId"] = user.id;
    } else {
      delete newQuery["agentId"];
    }
    search(newQuery);
    setChecked((current) => !current);
  };

  const searchPriority = ({ target: { value } }) => {
    let values = getFilterValues();
    let newQuery = { ...query, ...values };
    if (value !== 0) {
      newQuery["priority"] = value;
    } else {
      delete newQuery["priority"];
    }
    search(newQuery);
    setPriority(value);
  };

  const reset = () => {
    let values = form.getFieldsValue(true);
    let newQuery = { ...query };
    for (var propName in values) {
      delete newQuery[propName];
    }
    delete newQuery["agentId"];
    search(newQuery);
    form.resetFields();
    setChecked(false);
  };

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={4}>
          <Space>
            <Switch checked={checked} onChange={assignedToMe} />
            <Text>Toegewezen aan mij</Text>
          </Space>
        </Col>
        <Col span={8}>
          <Space>
            <Text>Prioriteit: </Text>
            <Radio.Group
              options={prioritiesRadio}
              onChange={searchPriority}
              value={priority}
              optionType="button"
              buttonStyle="solid"
            />
          </Space>
        </Col>
      </Row>
      <Form
        form={form}
        name="advanced_search"
        className="ant-advanced-search-form"
        onFinish={onFinish}
        // layout="inline"
      >
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item label="id" name="id">
              <Input placeholder="Zoek op id" allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label="Status">
              <Select
                showSearch
                placeholder="Kies een status"
                options={filterStatus}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Klant" name="userId">
              <Select
                showSearch
                placeholder="Kies een klant"
                optionFilterProp="label"
                options={filterUsers}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Space>
              <Button type="primary" htmlType="submit">
                Zoeken
              </Button>
              <Button onClick={reset}>Reset</Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </>
  );
}
