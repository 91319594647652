import React from "react";
import "./PreLoader.css";
import { Spin } from "antd";

export default function PreLoader() {
  return (
    <div>
      <Spin tip="Loading..." size="large" className="spinner" />
    </div>
  );
}
