import React from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import { Steps, Button } from "antd";

import "./CreateIssue.css";
import IssueForm from "./IssueForm";
import CustomHeader from "../Header";

const { Content } = Layout;
const { Step } = Steps;

function CreateIssue({ user }) {
  const [current, setCurrent] = React.useState(0);
  const { t } = useTranslation("user");

  const steps = [
    {
      title: t("createIssue.steps.username"),
      content: "username",
    },
    {
      title: t("createIssue.steps.subject"),
      content: "subject",
    },
    {
      title: t("createIssue.steps.description"),
      content: "content",
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <CustomHeader selectedKeys={["createIssue"]} />
      <Content style={{ padding: "0 200px" }}>
        <div className="CreateIssue">
          <div className="site-layout-content">
            <h1>{t("createIssue.title")}</h1>

            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className="steps-content">
              <IssueForm
                step={steps[current].content}
                triggerNext={next}
                user={user}
              />
            </div>
            <div className="steps-action">
              {current < steps.length - 1 && (
                <Button
                  // disabled={!validation}
                  type="primary"
                  onClick={() => next()}
                >
                  {t("createIssue.next")}
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  {t("createIssue.prev")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}

export default CreateIssue;
