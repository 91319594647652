import React from "react";
import { Layout, Button, Modal, message } from "antd";
import { useParams, useHistory } from "react-router-dom";

import TicketView from "../../common/ticketView/TicketView";
import connect from "../../common/utils/SocketClient";
import { client } from "../../common/utils/ApiClient";
import LeftInformationCard from "./LeftInformationCard";
import NotFound from "../../common/statusPages/NotFound";
import RightInformationCard from "./RightInformationCard";

const { Content, Sider } = Layout;

export default function DetailView({ user }) {
  const { ticketId } = useParams();
  const history = useHistory();
  const [ticketUser, setTicketUser] = React.useState(null);
  const [ticket, setTicket] = React.useState(null);
  const [notFound, setNotFound] = React.useState(false);
  const [isArchived, setIsArchived] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isTypeModalOpen, setIsTypeModalOpen] = React.useState(false);

  React.useEffect(() => {
    client(`tickets/${ticketId}`, { token: user.access_token })
      .then((data) => {
        setTicket(data);
        setIsArchived(data.status === "Done");
        setTicketUser(data.user);
      })
      .catch((response) => {
        if (response.status === 404) {
          setNotFound(true);
        } else {
          message.error("Er is iets misgelopen met het ophalen van de data.");
        }
      });
  }, [user.access_token, ticketId]);

  React.useEffect(() => {
    const socket = connect(user.access_token);
    socket.on(`ticket-${ticketId}`, (data) => {
      delete data.comments;
      setTicket((ticket) => ({ ...ticket, data }));
      setIsArchived(data.status === "Done");
      setTicketUser(data.user);
    });
    socket.on(`agent-ticket-${ticketId}-comments`, (data) => {
      setTicket((ticket) => {
        let newComments = ticket.comments.slice();
        newComments.push(data);
        return { ...ticket, comments: newComments };
      });
    });
    return () => {
      socket.emit("read-ticket", ticketId, user.isAgent);
      socket.disconnect();
    };
  }, [ticketId, user.access_token, user.isAgent]);

  const handleArchive = () => {
    if (!ticket.type) {
      Modal.error({
        title: "Ticket onvolledig!",
        content:
          "Het ticket is onvolledig. Je moet nog een type kiezen voordat je een ticket kan archiveren!",
      });
      return;
    }
    client(`tickets/${ticketId}/archive`, { token: user.access_token }).then(
      () => {
        message.success("Ticket has been archived");
        history.push("/agent/overview");
      }
    );
  };

  const handleSend = () => {
    if (!ticket.type) {
      setIsTypeModalOpen(true);
    }
    setIsModalOpen(true);
  };

  return notFound ? (
    <NotFound />
  ) : ticket ? (
    <Layout style={{ padding: "24px 0" }}>
      <Sider width="15vw">
        {ticketUser ? (
          <LeftInformationCard ticketUser={ticketUser} user={user} />
        ) : null}
      </Sider>
      <Content style={{ padding: "0 20px" }}>
        <TicketView
          user={user}
          ticket={ticket}
          username={user.username}
          handleSend={handleSend}
        />
      </Content>
      <Sider width="15vw">
        <RightInformationCard
          user={user}
          ticket={ticket}
          setTicket={setTicket}
          isArchived={isArchived}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isTypeModalOpen={isTypeModalOpen}
          setIsTypeModalOpen={setIsTypeModalOpen}
          handleSend={handleSend}
        />
        <div style={{ margin: "10px" }}>
          <Button
            disabled={isArchived}
            style={{ display: "block", margin: "auto" }}
            type="primary"
            onClick={handleArchive}
          >
            Archiveer
          </Button>
        </div>
      </Sider>
    </Layout>
  ) : null;
}
