import React from "react";
import { List, Card, Statistic, message, Tag, Typography } from "antd";

import { client } from "../../../common/utils/ApiClient";

const { Text } = Typography;

export default function StatusCard({ user }) {
  const [statusses, setStatusses] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    client("statistics/tickets/open/status", { token: user.access_token })
      .then((response) => {
        setData(response);
        const statusses = [...new Set(response.map((item) => item.status))];
        setStatusses(statusses);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token]);

  const renderTitle = (status) => {
    status = status.toUpperCase();
    let color = "";
    switch (status) {
      case "TO DO":
        color = "magenta";
        break;
      case "IN PROGRESS":
        color = "volcano";
        break;
      case "WAITING ON AVEVE":
        color = "lime";
        break;
      case "WAITING ON CUSTOMER":
        color = "gold";
        break;
      case "WAITING ON SUPPLIER":
        color = "orange";
        break;
      default:
        color = "green";
    }
    return (
      <Tag color={color} key={status}>
        {status}
      </Tag>
    );
  };

  const typeList = (datasource) => {
    return (
      <List
        size="small"
        split={false}
        dataSource={datasource}
        renderItem={(item) => (
          <List.Item style={{ padding: "4px 16px" }}>
            <Text>
              {item.type}: {item.value}
            </Text>
          </List.Item>
        )}
      />
    );
  };

  return (
    <List
      grid={{ gutter: 16, column: 6 }}
      dataSource={statusses}
      renderItem={(status) => (
        <List.Item>
          <Card bordered={false}>
            <Statistic
              title={renderTitle(status)}
              value={
                data
                  .filter((item) => item.status === status)
                  .reduce(
                    (a, b) => ({
                      value: a.value + b.value,
                    }),
                    { value: 0 }
                  ).value
              }
            />
            {typeList(data.filter((item) => item.status === status))}
          </Card>
        </List.Item>
      )}
    />
  );
}
