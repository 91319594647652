import React from "react";

import { Typography, Button, Card, message } from "antd";
import { FormOutlined } from "@ant-design/icons";

import { InputModal } from "../detailView/Modals";
import { client } from "../../common/utils/ApiClient";

const { Paragraph } = Typography;

export default function CompanyNote({ user, note, companyId }) {
  const [isVisibleEditCompanyNote, setIsVisibleEditCompanyNote] =
    React.useState(false);
  const [companyNote, setCompanyNote] = React.useState(note);
  const [inputText, setInputText] = React.useState(null);

  const handleCancel = () => {
    setIsVisibleEditCompanyNote(false);
  };

  const updateCompanyNote = () => {
    const data = {
      content: inputText,
      company_id: companyId,
    };
    client(`company-notes`, { data, token: user.access_token })
      .then((responseData) => message.success("De notitie is opgeslagen!"))
      .catch(() =>
        message.error("Er is iets misgelopen met het opslaan van de notitie!")
      );
    setCompanyNote(inputText);
    setIsVisibleEditCompanyNote(false);
  };

  return (
    <>
      <Card title="Klanten notities" style={{ marginTop: 16 }}>
        <Paragraph>
          {companyNote ? companyNote : "Er is geen notitie beschikbaar!"}
        </Paragraph>
        <Button
          type="primary"
          shape="round"
          icon={<FormOutlined />}
          onClick={() => setIsVisibleEditCompanyNote(true)}
        >
          Wijzig de notitie
        </Button>
      </Card>
      <InputModal
        isModalOpen={isVisibleEditCompanyNote}
        handleOk={updateCompanyNote}
        handleCancel={handleCancel}
        setInput={setInputText}
        title="Nieuwe notitie aanmaken"
        body="Geef hier uw opmerking in:"
        okText="Opslaan"
        cancelText="Annuleren"
        isTextArea={true}
        defaultValue={companyNote}
      />
    </>
  );
}
