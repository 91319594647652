import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";
import { Layout } from "antd";
import useSound from "use-sound";

import Overview from "./overview/Overview";
import LeftSider from "./LeftSider";
import DetailView from "./detailView/DetailView";
import Profile from "./profile/Profile";
import CreateIssue from "./createIssue/CreateIssue";
import Actual from "./statistics/Actual";
import Search from "./search/Search";
import Customers from "./customers/Customers";
import WelcomeMessage from "./welcomeMessage/WelcomeMessage";
import connect from "../common/utils/SocketClient";
import { useLocalStorageState } from "../common/utils/CustomHooks";
import CustomerDetail from "./CustomerDetail/CustomerDetail";
import Timesheets from "./timesheets/Timesheets";
import Period from "./statistics/Period";
import Year from "./statistics/Year";

const { Content } = Layout;

export default function Agent({ user, logout }) {
  const [sound] = useLocalStorageState("sound", true);
  const [play] = useSound("/sounds/swiftly-610.mp3");
  const [critical] = useSound("/sounds/railroad_crossing_bell.mp3", {volume: 0.5});

  React.useEffect(() => {
    const socket = connect(user.access_token);
    socket.on(
      `agent-notification`,
      () => {
        play();
      },
      [user.access_token, play, sound]
    );
    socket.on(
      `critical-notification`,
      () => {
        critical();
      },
      [user.access_token, play, sound]
    );
  });
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <LeftSider logout={logout} />
      <Layout className="site-layout">
        <Content style={{ margin: "0 16px" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Switch>
              <Redirect exact path="/agent" to="/agent/overview" />
              <Route exact path="/agent/overview">
                <Overview user={user} isActive={true} />
              </Route>
              <Route exact path="/agent/archive">
                <Overview user={user} isActive={false} />
              </Route>
              <Route exact path="/agent/search">
                <Search user={user} />
              </Route>
              <Route exact path="/agent/create-issue">
                <CreateIssue user={user} />
              </Route>
              <Route exact path="/agent/statistics/actual">
                <Actual user={user} />
              </Route>
              <Route exact path="/agent/statistics/period">
                <Period user={user} />
              </Route>
              <Route exact path="/agent/statistics/year">
                <Year />
              </Route>
              <Route exact path="/agent/timesheets">
                <Timesheets user={user} />
              </Route>
              <Route exact path="/agent/customers">
                <Customers user={user} />
              </Route>
              <Route exact path="/agent/customers/:customerId">
                <CustomerDetail user={user} />
              </Route>
              <Route exact path="/agent/profile">
                <Profile user={user} />
              </Route>
              <Route path="/agent/overview/:ticketId">
                <DetailView user={user} />
              </Route>
              <Route path="/agent/welcome-message">
                <WelcomeMessage user={user} />
              </Route>
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
