import React from "react";
import "./Reply.css";
import { Input, Form, Button, Upload, Checkbox } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function Reply({
  form,
  onFinish,
  isArchived,
  fileList,
  setFileList,
  agent = false,
}) {
  const [messageHidden, setMessageHidden] = React.useState(false);
  const { t } = useTranslation("common");

  const beforeUpload = (_, files) => {
    setFileList([...fileList, ...files]);
    return false;
  };

  const onRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  // Not completly sure why, but we need this
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form
      form={form}
      onFinish={(e) => {
        onFinish(e);
        setMessageHidden(false);
      }}
    >
      <Form.Item name="content" required={true}>
        <Input.TextArea
          disabled={isArchived}
          rows={4}
          placeholder={t("reply.placeholder")}
          style={{
            backgroundColor: messageHidden ? "#2b1d11" : "",
          }}
        />
      </Form.Item>
      <Form.Item name="hidden" hidden={!agent} valuePropName="checked">
        <Checkbox
          onChange={() => setMessageHidden(!messageHidden)}
          checked={messageHidden}
        >
          Verberg voor gebruikers
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="files"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        className="upload"
      >
        <Upload
          name="file"
          beforeUpload={beforeUpload}
          onRemove={onRemove}
          filelist={fileList}
          multiple={true}
        >
          <Button disabled={isArchived} icon={<UploadOutlined />}>
            {t("reply.attachment")}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item className="submit">
        <Button disabled={isArchived} htmlType="submit" type="primary">
          {t("reply.send")}
        </Button>
      </Form.Item>
    </Form>
  );
}
