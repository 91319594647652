import axios from "axios";
const localStorageKey = "__auth_provider_token__";

async function getToken() {
  // if we were a real auth provider, this is where we would make a request
  // to retrieve the user's token. (It's a bit more complicated than that...
  // but you're probably not an auth provider so you don't need to worry about it).
  return window.localStorage.getItem(localStorageKey);
}

function setToken(user) {
  window.localStorage.setItem(localStorageKey, user.access_token);
  return user;
}

function login({ username, password }) {
  let bodyFormData = new FormData();
  bodyFormData.append("username", username);
  bodyFormData.append("password", password);
  return client("login/access-token", bodyFormData).then(setToken);
}

async function logout() {
  window.localStorage.removeItem(localStorageKey);
}

const apiURL = process.env.REACT_APP_API_URL;

async function client(endpoint, data) {
  const config = {
    method: "POST",
    data,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  return axios(`${apiURL}/api/${endpoint}`, config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return Promise.reject(error);
    });
}

export { getToken, login, logout, localStorageKey, setToken };
