import React from "react";
import { Card } from "antd";

import TicketsPerMonthGraph from "../graphs/TicketsPerMonthGraph";
import HoursPerMonthGraph from "../graphs/HoursPerMonthGraph";
import MedianLeadTimeGraph from "../graphs/MedianLeadTimeGraph";

const tabList = [
  {
    key: "tickets",
    tab: "Ticketten",
  },
  {
    key: "hoursWorked",
    tab: "Uren gewerkt",
  },
  {
    key: "medianLeadTime",
    tab: "Mediaan doorloop tijd",
  },
];

export default function YearOverviewCard({ user, year }) {
  const [key, setKey] = React.useState("tickets");

  const content = {
    tickets: <TicketsPerMonthGraph year={year} />,
    hoursWorked: <HoursPerMonthGraph year={year} />,
    medianLeadTime: <MedianLeadTimeGraph year={year} />,
  };

  return (
    <Card
      style={{ width: "100%" }}
      tabList={tabList}
      activeTabKey={key}
      onTabChange={(key) => setKey(key)}
    >
      {content[key]}
    </Card>
  );
}
