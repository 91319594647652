import React from "react";
import { Typography, Row, Col } from "antd";
import { MixChart, MixProps } from "@opd/g2plot-react";

import { client } from "../../../common/utils/ApiClient";
import { formatDateTime } from "../../../common/utils/DateHelper";

const { Title, Text } = Typography;

export default function ClosedGraph({ user, range }) {
  const [graphData, setGraphData] = React.useState({
    Software: [],
    Hardware: [],
    LSNAV: [],
    O365: [],
  });
  const [graphDataNumbers, setGraphDataNumbers] = React.useState({
    Software: 0,
    Hardware: 0,
    LSNAV: 0,
    O365: 0,
    Total: 0,
  });
  const chartRef = React.useRef();

  React.useEffect(() => {
    client(
      `statistics/tickets/closed/graph-values?start=${formatDateTime(
        range[0],
        "YYYY-MM-DD"
      )}&end=${formatDateTime(range[1], "YYYY-MM-DD")}`,
      {
        token: user.access_token,
      }
    )
      .then((response) => {
        setGraphData(response);
        let result = {};
        let total = 0;
        for (let key in response) {
          const value = response[key].reduce((a, b) => a + b.value, 0);
          result[key] = value;
          total += value;
        }
        result["Total"] = total;
        setGraphDataNumbers(result);
      })
      .catch(() => setGraphData([]));
  }, [user.access_token, range]);

  const config: MixProps = {
    tooltip: false,
    legend: {
      itemValue: {
        formatter: (text, item) => {
          let items = [];
          for (let key in graphData) {
            items = items.concat(
              graphData[key].filter((d) => d.period === item.value)
            );
          }
          return items.length
            ? `(${items.reduce((a, b) => a + b.value, 0)})`
            : "-";
        },
      },
    },
    plots: [
      {
        type: "pie",
        region: { start: { x: 0, y: 0 }, end: { x: 0.2, y: 1 } },
        options: {
          data: graphData.Software,
          angleField: "value",
          colorField: "period",
          radius: 0.85,
          tooltip: {
            showMarkers: false,
          },
          label: {
            type: "inner",
            offset: "-15%",
            content: ({ percent, value }) =>
              `${(percent * 100).toFixed(0)}%(${value})`,
          },
          interactions: [
            { type: "element-active" },
            { type: "association-tooltip" },
            { type: "association-highlight" },
          ],
        },
      },
      {
        type: "pie",
        region: { start: { x: 0.25, y: 0 }, end: { x: 0.45, y: 1 } },
        options: {
          data: graphData.Hardware,
          radius: 0.85,
          angleField: "value",
          colorField: "period",
          label: {
            type: "inner",
            offset: "-15%",
            content: ({ percent, value }) =>
              `${(percent * 100).toFixed(0)}%(${value})`,
          },
          tooltip: {
            showMarkers: false,
          },
          interactions: [
            { type: "association-tooltip" },
            { type: "association-selected" },
          ],
        },
      },
      {
        type: "pie",
        region: { start: { x: 0.5, y: 0 }, end: { x: 0.7, y: 1 } },
        options: {
          data: graphData.LSNAV,
          angleField: "value",
          colorField: "period",
          radius: 0.85,
          tooltip: {
            showMarkers: false,
          },
          label: {
            type: "inner",
            offset: "-15%",
            content: ({ percent, value }) =>
              `${(percent * 100).toFixed(0)}%(${value})`,
          },
          interactions: [
            { type: "element-active" },
            { type: "association-tooltip" },
            { type: "association-highlight" },
          ],
        },
      },
      {
        type: "pie",
        region: { start: { x: 0.75, y: 0 }, end: { x: 0.95, y: 1 } },
        options: {
          data: graphData.O365,
          radius: 0.85,
          angleField: "value",
          colorField: "period",
          label: {
            type: "inner",
            offset: "-15%",
            content: ({ percent, value }) =>
              `${(percent * 100).toFixed(0)}%(${value})`,
          },
          tooltip: {
            showMarkers: false,
          },
          interactions: [
            { type: "association-tooltip" },
            { type: "association-selected" },
          ],
        },
      },
    ],
  };

  return (
    <>
      <Row>
        <Col span={6}>
          <Title level={4}>
            Software
            <Text type="secondary"> ({graphDataNumbers["Software"]})</Text>
          </Title>
        </Col>
        <Col span={6}>
          <Title level={4}>
            Hardware
            <Text type="secondary"> ({graphDataNumbers["Hardware"]})</Text>
          </Title>
        </Col>
        <Col span={6}>
          <Title level={4}>
            LSNAV<Text type="secondary"> ({graphDataNumbers["LSNAV"]})</Text>
          </Title>
        </Col>
        <Col span={5}>
          <Title level={4}>
            O365<Text type="secondary"> ({graphDataNumbers["O365"]})</Text>
          </Title>
        </Col>
        <Col span={1}>
          <Title level={5}>
            <Text type="secondary"> ({graphDataNumbers["Total"]})</Text>
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <MixChart {...config} chartRef={chartRef} />
        </Col>
      </Row>
    </>
  );
}
