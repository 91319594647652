import React from "react";

import {
  Descriptions,
  Drawer,
  List,
  Collapse,
  Tooltip,
  Typography,
  message,
  Modal,
  Button,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { client } from "../../common/utils/ApiClient";
import { InputModal } from "./Modals";
import { formatDateTime } from "../../common/utils/DateHelper";

const { Panel } = Collapse;

const genDescriptionTitle = ({ description, purchaseDate }) => {
  return (
    <>
      <Typography.Text style={{ width: "65%", margin: "0 10px" }} ellipsis>
        {description}
      </Typography.Text>
      <Typography.Text>{purchaseDate}</Typography.Text>
    </>
  );
};

export default function ProductInformationDrawer({
  open,
  setOpen,
  user,
  ticketUser,
}) {
  const [data, setData] = React.useState();
  // Soley for triggering rerender after update request
  const [update, setUpdate] = React.useState(false);

  const [selectedProductId, setSelectedProductId] = React.useState();
  const [productNote, setProductNote] = React.useState();
  const [isOpenNewProductNote, setIsOpenNewProductNote] = React.useState(
    false
  );

  React.useEffect(() => {
    client(`users/${ticketUser.id}/products`, { token: user.access_token })
      .then((response) => setData(response))
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  }, [user.access_token, ticketUser.id, update]);

  const disableProduct = (productId) => {
    const data = { isActive: false };
    client(`products/${productId}`, {
      token: user.access_token,
      data,
      method: "PUT",
    })
      .then(() => {
        message.success(`Product met id ${productId} is op non-actief gezet.`);
        setUpdate(!update);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  };

  const addNewProductNote = () => {
    const data = { content: productNote, productId: selectedProductId };
    client("product-notes", {
      token: user.access_token,
      data,
    })
      .then(() => {
        setSelectedProductId(null);
        setIsOpenNewProductNote(false);
        message.success(`Nieuwe notitie toegevoegd.`);
        setUpdate(!update);
      })
      .catch(() =>
        message.error("Er is iets misgelopen met het ophalen van de data.")
      );
  };

  const handleCancel = () => {
    setSelectedProductId(null);
    setIsOpenNewProductNote(false);
  };

  const genExtra = (productId) => (
    <>
      <Tooltip title="Dit zet het product op non-actief">
        <DeleteOutlined
          onClick={(event) => {
            event.stopPropagation();
            Modal.confirm({
              title: "Ben je zeker?",
              content:
                "Ben je zeker dat je dit product op non-actief wilt zetten? Het zal dan niet langer getoont worden in het product overzicht binnen in een ticket. Je kan non-actieve producten wel nog steeds bekijken in het algemene overzicht van de klant.",
              onOk: () => disableProduct(productId),
            });
          }}
        />
      </Tooltip>
    </>
  );

  const handleOnClickNewProductNote = (productId) => {
    setSelectedProductId(productId);
    setIsOpenNewProductNote(true);
  };

  return (
    <>
      <Drawer
        width={480}
        title="Product informatie"
        placement="left"
        onClose={() => setOpen(false)}
        open={open}
      >
        <List
          dataSource={data}
          renderItem={(item) => (
            <>
              <Collapse>
                <Panel
                  header={genDescriptionTitle(item)}
                  key={item.id}
                  extra={genExtra(item.id)}
                >
                  <Descriptions column={1} size="small">
                    <Descriptions.Item label="Omschrijving">
                      {item.description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Aanschafdatum">
                      {item.purchaseDate}
                    </Descriptions.Item>
                    <Descriptions.Item label="Serienummer">
                      {item.serialNumber}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Notities"
                      style={{
                        display: !item.productNotes.length ? "None" : null,
                      }}
                    >
                      <List
                        dataSource={item.productNotes}
                        renderItem={(item) => (
                          <List.Item>
                            <Tooltip
                              title={`${
                                item.createdBy.username
                              } op ${formatDateTime(item.createdAt)}`}
                            >
                              {item.content}
                            </Tooltip>
                          </List.Item>
                        )}
                      />
                    </Descriptions.Item>
                  </Descriptions>
                  <Button
                    shape="round"
                    icon={<PlusOutlined />}
                    size="small"
                    onClick={() => handleOnClickNewProductNote(item.id)}
                  >
                    Nieuwe notitie
                  </Button>
                </Panel>
              </Collapse>
            </>
          )}
        />
      </Drawer>
      <InputModal
        isOpen={isOpenNewProductNote}
        handleOk={addNewProductNote}
        handleCancel={handleCancel}
        setInput={setProductNote}
        title="Nieuwe notitie aanmaken"
        body="Geef hier uw opmerking in:"
      />
    </>
  );
}
